import React from "react";

function getClassName(type, className) {
    let classStr;
    if (type === "text" || type === "number" || type === "email" || type === "password") {
        classStr = "form-control text-secondary";
    }
    if (type === "number") {
        classStr = classStr.concat(" num-width");
    }

    if (type === "checkbox" || type === "radio") {
        classStr = "form-check-input";
    }

    return classStr + " " + className;
}

const renderField = ({
    input,
    label,
    type,
    placeholder,
    min,
    max,
    className,
    meta: { touched, error },
}) => (
    <fieldset className="form-group mb-4">
        <label className="bmd-label-floating mb-1">{label}</label>
        <div>
            <input
                className={getClassName(type, className)}
                {...input}
                type={type}
                min={min}
                max={max}
                placeholder={placeholder}
            />
            {touched && error && <span className="invalid-feedback d-block">{error}</span>}
        </div>
    </fieldset>
);

export default renderField;
