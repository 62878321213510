import React from "react";
import "../css/PlaceItem.css";

const FirstCreateCard = (props) => {
    return (
        <div className="mb-5">
            <div className="card fc-back-card" onClick={props.createPlace}>
                <div className=" fc-back-image "></div>
                <div className="fc-back-body">
                    <div className="btn btn-link fc-button-title">
                        Créez votre premier lieu Starplace
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstCreateCard;
/*
            <div className="card fc-card">
                    <div className="center mt-5 mb-3">
                        <img src="/welcome-card.jpg" alt="" className="center fc-main-picture" />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Hello</h5>
                        <p className="card-text">worls</p>
                    </div>
                    <div className="card-body"></div>
                </div>
        */
