export const SET_AUTHENTIFICATION = "SET_AUTHENTIFICATION";
export const SET_AUTHENTIFICATION_TOKEN = "SET_AUTHENTIFICATION_TOKEN";
export const PARSE_MESSAGE = "PARSE_MESSAGE";
export const PARSE_ERROR = "PARSE_ERROR";
export const RESET_ERROR = "RESET_ERROR";

export const SET_CUSTOMER = "SET_CUSTOMER";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const SET_PLACE_LIST = "SET_PLACE_LIST";
export const SET_CURRENT_PLACE = "SET_CURRENT_PLACE";
export const RESET_CURRENT_PLACE = "RESET_CURRENT_PLACE";
export const RESET_ALL_PLACES = "RESET_ALL_PLACES";

export const SET_OFFERS = "SET_OFFERS";
export const SET_TODAY_STATS = "SET_TODAY_STATS";
export const SET_YEAR_STATS = "SET_YEAR_STATS";
