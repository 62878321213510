import React, { Component } from "react";
import { connect } from "react-redux";
import Errors from "./Errors";
import { displayError } from "../actions";

class CheckEmail extends Component {
    componentDidMount() {}

    render() {
        return (
            <div>
                <div className="center mt-5 mb-3">
                    <img src="/banner-starplace.png" width="400" alt="" className="center" />
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4">
                        <Errors />
                    </div>
                </div>
                <div className="margin-auto ml-5 mr-5">
                    <div className="center mt-5 h5">Votre compte est presque opérationnel.</div>
                    <div className="center mt-2 h5">
                        Afin de finaliser votre inscription à Starplace, merci de cliquer sur le
                        lien que nous venons de vous envoyer par email.
                    </div>
                    <div className="center mt-4">
                        Cette opération est nécessaire afin de pouvoir vous connecter à votre
                        compte.
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, { displayError })(CheckEmail);
