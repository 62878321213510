import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Field, reduxForm } from "redux-form";
import Errors from "./Errors";
import validateCustomer from "./validateCustomer";
import renderField from "./renderField";

const FIELDS = {
    password: "password",
    newpassword: "newpassword",
    newpasswordcheck: "newpasswordcheck",
};

class CustomerPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordChange: false,
            loading: false,
            success: false,
        };
    }

    componentDidMount() {}

    getLoadingClassName = () => {
        if (this.state.loading) {
            return "spinner-border spinner-border-sm mr-2";
        } else {
            return "d-none";
        }
    };

    handleSubmit = (values) => {
        this.setState({ loading: true }, () => {
            this.props.changePassword(
                values,
                () => {
                    console.log("password changes ....");
                    this.props.initialize({ password: "", newpassword: "", newpasswordcheck: "" });
                    this.setState({ success: true, loading: false });

                    setTimeout(() => {
                        this.props.stopPasswordChange();
                    }, 2000);
                },
                () => {
                    this.setState({ loading: false });
                }
            );
        });
    };

    stopPasswordChange = () => {
        this.props.stopPasswordChange();
    };

    render() {
        // this.analyzePassword(this.props.passwordvalue);

        return (
            <div style={{ maxWidth: 550 }} className="margin-auto card  py-2 px-4">
                <div className="">
                    <div className="section-title mb-4">Modification de votre mot de passe</div>
                    {this.state.success && (
                        <div style={{ color: "blue" }} className="center">
                            Mot de passe modifié avec succès!
                        </div>
                    )}
                    <form className="" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <div style={{ margin: "auto", maxWidth: 300 }} className="mt-4 mb-4">
                            <Field
                                name={FIELDS.password}
                                type="password"
                                component={renderField}
                                label="Mot de passe actuel"
                            />

                            <Field
                                name={FIELDS.newpassword}
                                type="password"
                                component={renderField}
                                label="Nouveau mot de passe"
                            />
                            <Field
                                name={FIELDS.newpasswordcheck}
                                type="password"
                                component={renderField}
                                label="Nouveau mot de passe (vérification)"
                            />
                        </div>
                        <div style={{ width: "95%" }} className="margin-auto">
                            <div>
                                <Errors />
                            </div>
                        </div>
                        <div className="center mt-4 mb-4">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={this.state.loading}>
                                <span
                                    className={this.getLoadingClassName()}
                                    role="status"
                                    aria-hidden="true"></span>
                                Modifier mon mot de passe
                            </button>
                        </div>
                        <div className="center mt-3">
                            <button onClick={this.stopPasswordChange} className="btn btn-link ">
                                Annuler
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const customerPasswordForm = reduxForm({
    form: "customerPassword",
    fields: Object.keys(FIELDS),
    validate: validateCustomer,
})(CustomerPassword);

const mapStateToProps = (state) => {
    return {
        customer: state.customer.current,
        // passwordvalue: state.form?.customerPassword?.values?.password,
    };
};
export default connect(mapStateToProps, actions)(customerPasswordForm);
