import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

class SubHeader extends Component {
    renderPlaceName = () => {
        return this.props.place ? this.props.place.name : null;
    };

    render() {
        return (
            <div>
                <ul className="nav navbar-expand-lg bg-light mb-4">
                    <li className="nav-item nav-link ml-3 mr-5 text-uppercase text-primary">
                        {this.renderPlaceName()}
                    </li>

                    <li className="nav-item">
                        <NavLink exact activeClassName="selected" className="nav-link" to="/places">
                            {"<"}Retour
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="selected" className="nav-link" to="/places/view">
                            Description
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="selected" className="nav-link" to="/places/stats">
                            Statistiques
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="selected" className="nav-link" to="/places/edit">
                            Edition
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            activeClassName="selected"
                            className="nav-link"
                            to="/places/subscription">
                            Abonnement
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { place: state.place.current };
};

export default connect(mapStateToProps, null)(SubHeader);
