const REQUIRED_FIELD = "Ce champ est requis";

const INVALID_URL =
    "L'URL du site est invalide : elle doit être de la forme http(s)://www.monsite.fr ";
const INVALID_PHONE = "Numéro de téléphone non valide";

function isValidHttpUrl(str) {
    let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
}

function isValidPhone(str) {
    let pattern = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im); // fragment locator
    return !!pattern.test(str);
}

const validate = (values) => {
    const errors = {};

    ////// FORMULAIRE 1 : INFO GENERALES //////
    if (!values.name) {
        errors.name = REQUIRED_FIELD;
    }

    if (!values.classification || values.classification === "") {
        errors.classification = REQUIRED_FIELD;
    }

    if (!values.address) {
        errors.address = REQUIRED_FIELD;
    }

    if (!values.web) {
        errors.web = REQUIRED_FIELD;
    } else {
        if (!isValidHttpUrl(values.web)) {
            errors.web = INVALID_URL;
        }
    }

    if (values.phone && !isValidPhone(values.phone)) {
        errors.phone = INVALID_PHONE;
    }

    if (!values.recordPolicy || values.recordPolicy === "") {
        errors.recordPolicy = REQUIRED_FIELD;
    }

    if (!values.recordMaxResolution || values.recordMaxResolution === "") {
        errors.recordMaxResolution = REQUIRED_FIELD;
    }

    if (!values.recordMaxSize) {
        errors.recordMaxSize = REQUIRED_FIELD;
    }

    if (!values.recordMaxDuration) {
        errors.recordMaxDuration = REQUIRED_FIELD;
    }

    if (!values.retentionDuration) {
        errors.retentionDuration = REQUIRED_FIELD;
    }

    if (!values.numberOfRecordPerUserPerDay) {
        errors.numberOfRecordPerUserPerDay = REQUIRED_FIELD;
    }

    if (!values.numberOfDownloadPerUser) {
        errors.numberOfDownloadPerUser = REQUIRED_FIELD;
    }

    if (!values.delayBetweenRecordDay) {
        errors.delayBetweenRecordDay = REQUIRED_FIELD;
    }

    if (!values.delayBeforeView && values.delayBeforeView !== 0) {
        errors.delayBeforeView = REQUIRED_FIELD;
    }

    /*
    if (!values.email) {
        errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Invalid email address";
    }*/

    return errors;
};

export default validate;
