import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import renderField from "./renderField";
const renderError = ({ meta: { touched, error } }) => {
    return (
        <div className="mb-4">
            {touched && error && <span className="invalid-feedback d-block">{error}</span>}
        </div>
    );
};

const PlaceFormPage1 = (props) => {
    const { handleSubmit, initialValues, loading, editMode, exitCallback } = props;

    useEffect(() => {
        // INIT VALUE HERE WITH THE ONES LOADED
        //props.dispatch(change("wizard", "tel", "phone number"));
        // TODO : ATTENTION on doit mettre un flag dans le state global pour eviter de recharger les données
        // tant qu'on a pas fait un submit ou une sauvegarde !!!! sinon les données seront écrasées si on
        // revient en arriere dans le wizard
        if (initialValues) {
            props.initialize(initialValues);
        }
    }, [initialValues]);

    return (
        <div className="row justify-content-md-center">
            <h4 className="center mb-4">Informations générales</h4>

            <form className="col-md-11" onSubmit={handleSubmit}>
                <Field
                    name="name"
                    type="text"
                    component={renderField}
                    label="Nom de votre lieu Starplace"
                />

                <div className="row select-wizard">
                    <label className="col" htmlFor="classification">
                        Nature du lieu :
                    </label>
                    <Field
                        name="classification"
                        id="classificaiton"
                        component="select"
                        className="col custom-select  select-wizard-bg">
                        <option>Sélectionner ...</option>
                        <option value="patrimoine">Patrimoine</option>
                        <option value="parc-jardin">Parcs et jardins</option>
                        <option value="parc-loisir">Parc de loisir</option>
                        <option value="site-naturel">Site naturel</option>
                    </Field>
                </div>
                <Field name="classification" component={renderError} />
                <Field name="address" type="text" component={renderField} label="Adresse" />

                <div className="row">
                    <div className="col">
                        <Field
                            name="web"
                            type="text"
                            component={renderField}
                            label="Site internet"
                        />
                    </div>
                    <div className="col">
                        <Field
                            name="phone"
                            type="text"
                            component={renderField}
                            label="Téléphone de l'accueil"
                        />
                    </div>
                </div>
                <div>
                    <button type="submit" className="btn btn-primary">
                        <span className={loading} role="status" aria-hidden="true"></span>
                        Sauvegarder et continuer
                    </button>

                    {editMode && (
                        <button
                            type="button"
                            onClick={exitCallback}
                            className="btn btn-link float-right">
                            Quitter l'édition
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default reduxForm({
    form: "wizard", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(PlaceFormPage1);
