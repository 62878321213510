import React from "react";
import { reduxForm } from "redux-form";
import validate from "./validate";
import MapDisplay from "../containers/MapDisplay";
const ZOOM_MIN_LEVEL = 6;

const PlaceFormPage4 = (props) => {
    const { previousPage, initialValues, loading, editMode, exitCallback } = props;
    let boundaries = initialValues.boundaries ? initialValues.boundaries : [];
    let gps = initialValues.gps ? initialValues.gps : [];
    let zoom = initialValues.zoom ? initialValues.zoom : ZOOM_MIN_LEVEL;

    function changeHandler(newzones, newgps, newzoom) {
        console.log("MAP CHANGE HANDLER :  ", newzones, newgps, newzoom);
        // do processing on picture here or update the Place picture
        console.log("changeHandler : ", newzones);
        boundaries = newzones;
        zoom = newzoom;
        gps = newgps;
    }

    function handleBoundaries() {
        console.log("SUBMIT MAP :  ", boundaries, gps, zoom);
        props.onSubmit({ boundaries, gps, zoom });
    }

    return (
        <div className="row justify-content-md-center">
            <div className="col-md-11">
                <MapDisplay
                    boundaries={boundaries}
                    gps={gps}
                    zoom={zoom}
                    onChange={changeHandler}
                />
                <div className=" mt-4">
                    <button type="button" className="btn btn-secondary mr-3" onClick={previousPage}>
                        Précédent
                    </button>
                    <button type="button" onClick={handleBoundaries} className="btn btn-primary">
                        <span className={loading} role="status" aria-hidden="true"></span>
                        Sauvegarder et continuer
                    </button>

                    {editMode && (
                        <button
                            type="button"
                            onClick={exitCallback}
                            className="btn btn-link float-right">
                            Quitter l'édition
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
export default reduxForm({
    form: "wizard", //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(PlaceFormPage4);
