import React, { useState } from "react";
import AlertConfirm from "./AlertConfirm";
import Offer from "./Offer";
import Errors from "./Errors";

function getFormatedDate(toConvertDate) {
    let date;
    if (typeof date === Date) {
        date = toConvertDate;
    } else {
        date = new Date(toConvertDate);
    }
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("fr-FR", options);
}

function isThereGreaterOffer(activeSubscription, allOffers) {
    for (let i = 0; i < allOffers.length; i++) {
        if (activeSubscription.offerFares < allOffers[i].price) {
            return true;
        }
    }
    return false;
}

function getActiveSubscription(subscriptions) {
    for (let i = 0; i < subscriptions.length; i++) {
        if (subscriptions[i].status === "active") {
            return subscriptions[i];
        }
        if (subscriptions[i].status === "resiliated") {
            const currentTime = new Date().getTime(); // get epoch time
            if (
                new Date(subscriptions[i].startDate).getTime() < currentTime &&
                currentTime < new Date(subscriptions[i].endDate).getTime()
            ) {
                return subscriptions[i];
            }
        }
    }
    return null;
}

function getStatusString(activeSubscription) {
    // ORDER OF TEST IS IMPORTANT
    if (activeSubscription.status === "resiliated") {
        return (
            "Vous avez résilié votre abonnement, il sera actif jusqu'au " +
            getFormatedDate(activeSubscription.endDate)
        );
    }

    if (activeSubscription.status === "active" && activeSubscription.renewDate) {
        return (
            "Vous avez renouvelé votre abonnement le " +
            getFormatedDate(activeSubscription.renewDate)
        );
    }

    if (activeSubscription.nextTo && activeSubscription.nextTo.length > 0) {
        return (
            "Vous avez changé de formule d'abonnement le " +
            getFormatedDate(activeSubscription.startDate)
        );
    }
}

const Subscription = ({
    subscriptions,
    offers,
    onSubscribe,
    onResiliate,
    onRenew,
    onCancelAutoRenew,
}) => {
    const [toggleHistory, setToggleHistory] = useState(false);
    const [alertTitle, setAlertTitle] = useState("Default title");
    const [alertContent, setAlertContent] = useState(<p>default content</p>);
    const [alertValidate, setAlertValidate] = useState(null);
    const [alertBig, setAlertBig] = useState(false);

    const activeSubscription = getActiveSubscription(subscriptions);
    const noOffer = !isThereGreaterOffer(activeSubscription, offers);

    function displayHistoryItem(item) {
        let action = "",
            compl = "",
            effectifLe = "",
            offre = "";
        let resiliation = false;

        if (!item.nextTo && !item.renewDate /*&& item.status !== "resiliated"*/) {
            action = "Abonnement";
            compl = " à l'offre ";
            offre = item.offerName;
            effectifLe = getFormatedDate(item.startDate);
        } else if (item.nextTo && !item.renewDate /*&& item.status !== "resiliated"*/) {
            action = "Changement d'abonnement";
            compl = " vers l'offre ";
            offre = item.offerName;
            effectifLe = getFormatedDate(item.startDate);
        } else if (item.renewDate /*item.status !== "resiliated"*/) {
            action = "Renouvellement de l'abonnement";
            effectifLe = getFormatedDate(item.renewDate);
            console.log("renew date : ", effectifLe);
        }

        // ATTENTION resiliation cas particulier
        if (item.status === "resiliated" && item.resiliationDate) {
            resiliation = true;
        }
        if (offre.length > 0) {
            offre = '"' + offre + '"';
        }

        return (
            <div>
                <p className="text-left text-secondary">
                    <span style={{ color: "black" }}>{effectifLe} : </span>

                    <strong>{action}</strong>

                    {compl}
                    <span className="">{offre}</span>
                </p>
                {resiliation && (
                    <p className="text-left text-secondary">
                        <span style={{ color: "black" }}>
                            {getFormatedDate(item.resiliationDate)} :{" "}
                        </span>
                        <strong style={{ color: "red" }}>Résiliation de l'abonnement</strong>
                    </p>
                )}
            </div>
        );
    }

    function onSubscribeOffer(offer) {
        window.$("#subscriptionAlert").modal("hide");
        if (onSubscribe) {
            onSubscribe(offer);
        }
    }

    function upgradeOffer(e) {
        e.preventDefault();

        if (!onSubscribe) return;
        setAlertBig(true);
        setAlertTitle("Changement de formule d'abonnement");

        setAlertContent(
            <div>
                <p style={{ fontSize: "1.3em" }} className="mt-3 center">
                    Choisissez ci-dessous la nouvelle formule pour votre abonnement.
                </p>
                <p className="mt-3 mb-5">
                    Vous serez facturé au prorata du temps restant de votre abonnement courant.
                </p>

                <div className="container">
                    <div className="d-flex justify-content-around flex-wrap mb-3 text-center">
                        {offers.map((offer, i) => {
                            if (activeSubscription.offerFares < offer.price) {
                                return (
                                    <Offer key={i} onSubscribe={onSubscribeOffer} offer={offer} />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        );
        setAlertValidate(() => {
            return undefined;
        });
        window.$("#subscriptionAlert").modal("show");
    }

    function resiliate(e) {
        e.preventDefault();

        setAlertBig(false);
        setAlertTitle("Résiliation de votre abonnement");
        setAlertContent(<p>Voulez-vous vraiment résilier votre abonnement Starplace ?</p>);
        setAlertValidate(() => {
            return function () {
                window.$("#subscriptionAlert").modal("hide");
                if (onResiliate) {
                    onResiliate(activeSubscription);
                }
            };
        });
        window.$("#subscriptionAlert").modal("show");
    }

    function stopAutoRenew(e) {
        e.preventDefault();

        setAlertBig(false);
        setAlertTitle("Arrêt du renouvellemet automatique");
        setAlertContent(
            <p>
                Voulez-vous suspendre le renouvellement automatique ? Vous pourrez continuer de
                renouveler votre abonnement de façon manuelle.
            </p>
        );
        setAlertValidate(() => {
            return function () {
                window.$("#subscriptionAlert").modal("hide");
                if (onCancelAutoRenew) {
                    onCancelAutoRenew(activeSubscription);
                }
            };
        });
        window.$("#subscriptionAlert").modal("show");
    }

    function renewSubscription(e) {
        e.preventDefault();

        setAlertBig(false);
        setAlertTitle("Renouveler votre abonnement");
        setAlertContent(<p>Veuillez confirmer le renouvellement de votre abonnement ?</p>);
        setAlertValidate(() => {
            return function () {
                window.$("#subscriptionAlert").modal("hide");
                if (onRenew) {
                    onRenew(activeSubscription);
                }
            };
        });
        window.$("#subscriptionAlert").modal("show");
    }

    function renderAllHistoryItems(subscriptions) {
        return (
            <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: "1.3em" }} className="m-3">
                    Votre historique :{" "}
                </div>
                {subscriptions.map((item, i) => {
                    return (
                        <div
                            style={{
                                backgroundColor: "WhiteSmoke",
                                maxWidth: "500px",
                                margin: "auto",
                                padding: "10px 10px 5px 10px",
                                marginTop: "10px",
                            }}
                            key={i}>
                            {displayHistoryItem(item)}
                        </div>
                    );
                })}
                <div style={{ fontSize: "1.3em" }} className="mt-5 mb-3">
                    Options sur votre abonnement :{" "}
                </div>
                <Errors />
                {(activeSubscription.type === "norenew" ||
                    (activeSubscription.type === "autorenew" &&
                        activeSubscription.autoRenew === false)) &&
                    activeSubscription.status === "active" && (
                        <div className="mt-2">
                            <button className="btn btn-link w-75" onClick={renewSubscription}>
                                Renouvelez votre abonnement
                            </button>
                        </div>
                    )}

                {activeSubscription.type === "autorenew" &&
                    activeSubscription.autoRenew &&
                    activeSubscription.status === "active" && (
                        <div className="mt-2">
                            <button className="btn  btn-link w-75" onClick={stopAutoRenew}>
                                Arrêter le renouvellement automatique
                            </button>
                        </div>
                    )}
                {activeSubscription.status === "active" && !noOffer && (
                    <div className="mt-2">
                        <button className="btn  btn-link w-75" onClick={upgradeOffer}>
                            Besoin de plus de capacité ? <strong>Changez d'offre !</strong>
                        </button>
                    </div>
                )}
                {activeSubscription.status === "active" && (
                    <div className="mt-2 mb-3">
                        <button className="btn  btn-link w-75" onClick={resiliate}>
                            Résilier mon abonnement
                        </button>
                    </div>
                )}
                {activeSubscription.status !== "active" && (
                    <div className="mt-2 mb-3 text-secondary">Aucune option disponible</div>
                )}
            </div>
        );
    }

    function renderSubscriptionHistory(subscriptions) {
        if (subscriptions.length > 0) {
            return (
                <div style={{ borderTop: "solid 1px lightgrey", textAlign: "right" }}>
                    <p className="mt-3">
                        <a
                            style={{ textDecoration: "underline" }}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setToggleHistory(!toggleHistory);
                            }}>
                            {!toggleHistory
                                ? "Historique et options"
                                : "Cacher l'historiques et les options"}
                        </a>
                    </p>
                    {toggleHistory ? renderAllHistoryItems(subscriptions) : null}
                </div>
            );
        }
    }

    function getPercentStr(value, max) {
        if (value > max) return "100%";

        let val = (value / max) * 100;
        if (val === 0) {
            val = 0.5;
        }
        return val + "%";
    }

    function drawBar(barTitle, currentValue, maxValue, unitStr, color) {
        //console.log("currentvalue:", currentValue);
        return (
            <div className="row m-3">
                <div className="col-3 text-right">{barTitle} : </div>
                <div
                    className="col-6 w-100"
                    style={{
                        backgroundColor: "lightgray",

                        padding: "2px 1px 2px 1px",
                    }}>
                    <div
                        style={{
                            height: "100%",
                            backgroundColor: color,
                            width: getPercentStr(currentValue, maxValue),
                        }}></div>
                </div>
                <div className="col-3 text-left">
                    <small>
                        {" "}
                        {currentValue} {unitStr} /{" "}
                        <strong>
                            {maxValue} {unitStr}
                        </strong>
                    </small>
                </div>
            </div>
        );
    }

    function round2Decimal(num) {
        return Math.round(num * 100) / 100;
    }

    function drawChart() {
        return (
            <div>
                <p className="text-secondary">Votre consommation : </p>
                {drawBar(
                    "Stockage",
                    round2Decimal(activeSubscription.doneStorageSize / (1024 * 1024)),
                    activeSubscription.maxStorageCapacity / (1024 * 1024),
                    "Go",
                    "yellow"
                )}
                {drawBar(
                    "Délivrabilité",
                    round2Decimal(activeSubscription.doneDownloadSize / (1024 * 1024)),
                    activeSubscription.maxDownloadCapacity / (1024 * 1024),
                    "Go",
                    "yellow"
                )}
            </div>
        );
    }

    if (activeSubscription) {
        return (
            <div>
                <div style={{ maxWidth: "700px", margin: "auto" }} className="card mb-3 shadow-sm">
                    <div className="card-header">
                        <h5 className="my-0 text-primary">{activeSubscription.offerName}</h5>
                    </div>
                    <div className="card-body">
                        <p>
                            Valable jusqu'au{" "}
                            <strong>{getFormatedDate(activeSubscription.endDate)}</strong>
                        </p>
                        <div className="mt-4 mb-4" id="chart_div">
                            {drawChart()}
                        </div>
                        <div>
                            <p>{getStatusString(activeSubscription)}</p>
                        </div>

                        <div className="mt-4">{renderSubscriptionHistory(subscriptions)}</div>
                    </div>
                </div>
                <AlertConfirm
                    id="subscriptionAlert"
                    big={alertBig}
                    title={alertTitle}
                    validateCallback={alertValidate}
                    cancelStr="Annuler"
                    validateStr="Confirmer">
                    {alertContent}
                </AlertConfirm>
            </div>
        );
    } else {
        return <div>Pas d'abonnement actif pour votre Starplace</div>;
    }
};
export default Subscription;
