import React from "react";
import { connect } from "react-redux";

const PlaceEdit = (props) => {
    function editPlace(e) {
        if (props.place) {
            props.history.push("/wizard");
        }
    }

    if (!props.place) {
        return (
            <div className="container mt-5 mb-5">
                <p style={{ fontSize: "1.3em" }} className="center">
                    Aucune Starplace séléctionnée
                </p>
            </div>
        );
    } else {
        return (
            <div>
                <p style={{ fontSize: "1.3em" }} className="center mt-5 mb-4">
                    Pour modifier la configuration de votre lieu Starplace, utilisez l'assistant de
                    configuration :
                </p>
                <div className="center">
                    <button onClick={editPlace} className="btn btn-primary">
                        Démarrer l'assistant
                    </button>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return { place: state.place.current };
};
export default connect(mapStateToProps, null)(PlaceEdit);
