const BASE_URL = "https://app.starplace.io/pro";
//const BASE_URL = "http://localhost:8081";

const CONSTANTS = {
    BASE_URL: BASE_URL,
    PICTURE_DIR_FILE_PATH: BASE_URL + "/pictures/",
    ASK_UPLOAD_URL: BASE_URL + "/place/picture/token/",
    UPDATE_PICTURE_URL: BASE_URL + "/place/picture/update/",
    VERIFY_CUSTOMER_TOKEN_URL: BASE_URL + "/customer/verify?id=",

    MESSAGE_DURATION_IN_MILLIS: 4000,

    NUMBER_OF_PICTURES: 1,
    IMAGE_MAX_WIDTH: 700,

    IMAGE_QUALITY_POURCENTAGE: 70,
    PICTURE_SELECTION_MAX_SIZE: 5000000,

    NETWORK_TIMOUT_MS: 4000,
};

export default CONSTANTS;
