import React from "react";
import Errors from "./Errors";
import StatsItem from "./StatsItem";
import { connect } from "react-redux";
import { loadStats } from "../actions/index";
import ReactChartKick, { AreaChart, ColumnChart } from "react-chartkick";
import Chart from "chart.js";

ReactChartKick.addAdapter(Chart);
//9AB3E6
// EEEEEE
class PlaceStats extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if (
            props.place &&
            props.stats.yearData &&
            props.stats.yearData.records &&
            props.stats.yearData.records.length > 0 &&
            state.currentData.records.length === 0
        ) {
            return PlaceStats.initStates(state.selectValue, props);
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            refresh: false,
            selectValue: "7d",
            currentInvitations: 0,
            currentRecords: 0,
            currentOpened: 0,
            currentViews: 0,
            currentData: { records: [], invitations: [], views: [], opened: [] },
        };
    }

    componentDidMount() {
        // by default load 1 year of stats and store in the global state of redux
        if (
            this.props.place &&
            this.props.stats.yearData &&
            this.props.stats.yearData.records &&
            this.props.stats.yearData.records.length === 0
        ) {
            console.log(this.props.place.placeId);
            this.props.loadStats(this.props.place.placeId);
        }
    }

    selectPeriod = (event) => {
        if (event.target.value) {
            const period = event.target.value;
            this.setState(PlaceStats.initStates(period, this.props));
        }
    };

    static initStates(period, props) {
        if (period === "today") {
            return {
                selectValue: period,
                currentInvitations: props.stats.todayStats.invitations,
                currentRecords: props.stats.todayStats.records,
                currentOpened: props.stats.todayStats.opened,
                currentViews: props.stats.todayStats.views,
                currentData: props.stats.todayData,
            };
        }
        if (period === "yesterday") {
            return {
                selectValue: period,
                currentInvitations: props.stats.yesterdayStats.invitations,
                currentRecords: props.stats.yesterdayStats.records,
                currentOpened: props.stats.yesterdayStats.opened,
                currentViews: props.stats.yesterdayStats.views,
                currentData: props.stats.yesterdayData,
            };
        }
        if (period === "7d") {
            return {
                selectValue: period,
                currentInvitations: props.stats.weekStats.invitations,
                currentRecords: props.stats.weekStats.records,
                currentOpened: props.stats.weekStats.opened,
                currentViews: props.stats.weekStats.views,
                currentData: props.stats.weekData,
            };
        }
        if (period === "30d") {
            return {
                selectValue: period,
                currentInvitations: props.stats.monthStats.invitations,
                currentRecords: props.stats.monthStats.records,
                currentOpened: props.stats.monthStats.opened,
                currentViews: props.stats.monthStats.views,
                currentData: props.stats.monthData,
            };
        }
        if (period === "365d") {
            return {
                selectValue: period,
                currentInvitations: props.stats.yearStats.invitations,
                currentRecords: props.stats.yearStats.records,
                currentOpened: props.stats.yearStats.opened,
                currentViews: props.stats.yearStats.views,
                currentData: props.stats.yearData,
            };
        }
        return null;
    }

    refresh = (e) => {
        this.setState({ refresh: true }, () => {
            this.props.loadStats(this.props.place.placeId, true);
            setTimeout(() => {
                this.setState({ refresh: false });
                if (this.state.selectValue === "today") {
                    this.setState({
                        currentInvitations: this.props.stats.todayStats.invitations,
                        currentRecords: this.props.stats.todayStats.records,
                        currentOpened: this.props.stats.todayStats.opened,
                        currentViews: this.props.stats.todayStats.views,
                        currentData: this.props.stats.todayData,
                    });
                }
            }, 1000);
        });
    };

    render() {
        if (!this.props.place) {
            return (
                <div className="container mt-5 mb-5">
                    <p style={{ fontSize: "1.3em" }} className="center">
                        Aucune Starplace séléctionnée
                    </p>
                </div>
            );
        } else {
            return (
                <div>
                    <Errors />
                    <div>
                        <div className="form-group mt-5">
                            <div className="d-flex justify-content-center align-items-center">
                                <label
                                    style={{ fontSize: "1.3em" }}
                                    className="mr-3"
                                    htmlFor="selectStatsPeriod">
                                    Afficher les statistiques pour :{" "}
                                </label>

                                <select
                                    name="period"
                                    value={this.state.selectValue}
                                    onChange={this.selectPeriod}
                                    className="form-control select-small custom-select select-small-bg"
                                    id="selectStatsPeriod">
                                    <option value="today">Aujourd'hui</option>
                                    <option value="yesterday">Hier</option>
                                    <option value="7d">Les 7 derniers jours</option>
                                    <option value="30d">Les 30 derniers jours</option>
                                    <option value="365d">Depuis 1 an</option>
                                </select>
                                <button
                                    disabled={this.state.refresh}
                                    onClick={this.refresh}
                                    className="btn btn-outline-primary ml-5">
                                    <i
                                        style={{ fontSize: "24px", verticalAlign: "middle" }}
                                        className="material-icons">
                                        refresh
                                    </i>{" "}
                                    Rafraîchir
                                </button>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: "40px" }} className="center row mx-4">
                        <div className="col-sm-6 col-lg-3">
                            <StatsItem
                                color="#444"
                                decoration="orange"
                                title="VIDEOS ENREGISTREES"
                                value={this.state.currentRecords}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <StatsItem
                                color="#444"
                                decoration="orange"
                                title="INVITATIONS ENVOYEES"
                                value={this.state.currentInvitations}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <StatsItem
                                color="#444"
                                decoration="orange"
                                title="VIDEOS LUES"
                                value={this.state.currentViews}
                            />
                        </div>
                    </div>

                    <div className="mt-5 center">
                        <div className="panel-heading">
                            <ul className="nav nav-tabs justify-content-center ml-4 mr-3">
                                <li className="nav-item">
                                    <a
                                        data-toggle="tab"
                                        className="nav-link active"
                                        href="#tabrecord">
                                        Vidéos enregistrées
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a data-toggle="tab" className="nav-link" href="#tabinvitation">
                                        Invitations envoyées
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a data-toggle="tab" className="nav-link" href="#tabrender">
                                        Vidéos lues
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="panel-body">
                            <div className="tab-content">
                                <div className="tab-pane active" id="tabrecord">
                                    <div className="m-4">
                                        <AreaChart
                                            curve={false}
                                            points={false}
                                            ytitle="Vidéos enregistrées"
                                            data={this.state.currentData.records}
                                        />
                                    </div>
                                </div>
                                <div className="tab-pane" id="tabinvitation">
                                    <div className="m-4">
                                        <AreaChart
                                            curve={false}
                                            points={false}
                                            ytitle="Invitations envoyées"
                                            data={this.state.currentData.invitations}
                                        />
                                    </div>
                                </div>
                                <div className="tab-pane" id="tabrender">
                                    <div className="m-4">
                                        <AreaChart
                                            curve={false}
                                            points={false}
                                            ytitle="Vidéos lues"
                                            data={this.state.currentData.views}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return { place: state.place.current, stats: state.stats };
};

const mapDispatchToProps = {
    loadStats,
};
export default connect(mapStateToProps, mapDispatchToProps)(PlaceStats);
