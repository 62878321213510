import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Errors from "./Errors";
import CountrySelect from "./CountrySelect";
import validateCustomer from "./validateCustomer";
import renderField from "./renderField";
import CONTRY_NAMES from "../misc/countryNames";
import ReCAPTCHA from "react-google-recaptcha";

import * as actions from "../actions";

const FIELDS = {
    addressLine1: "addressLine1",
    addressLine2: "addressLine2",
    city: "city",
    contactFirstName: "contactFirstName",
    contactLastName: "contactLastName",
    countryCode: "countryCode",
    email: "email",
    organization: "organization",
    password: "password",
    passwordcheck: "passwordcheck",
    phone: "phone",
    postalCode: "postalCode",
};

const renderError = ({ meta: { touched, error } }) => {
    return (
        <div className="mb-4">
            {touched && error && <span className="invalid-feedback d-block">{error}</span>}
        </div>
    );
};

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            recaptcha: null,
        };
    }
    componentDidMount() {
        this.props.initialize({ countryCode: "FRA" });
    }

    getLoadingClassName = () => {
        if (this.state.loading) {
            return "spinner-border spinner-border-sm mr-2";
        } else {
            return "d-none";
        }
    };

    handleSubmit = (data) => {
        if (data.password !== data.passwordcheck) {
            this.props.displayError("Les mots de passe de correspondent pas");
            return;
        }

        if (!this.state.recaptcha) {
            this.props.displayError(
                "Cliquez sur la case à cocher pour prouver que vous n'êtes pas un robot"
            );
            return;
        }

        let values = { ...data };
        // small hack to store both country code and country
        values.country = CONTRY_NAMES[data.countryCode];

        console.log("Valeurs du formulaire: ", values);
        this.setState({ loading: true }, () => {
            console.log("values: ", values);
        });

        this.props.signupUser(
            { ...values, recaptcha: this.state.recaptcha },
            () => {
                console.log("created ....");
                window.grecaptcha.reset();
                this.props.history.push("/email");
            },
            () => {
                window.grecaptcha.reset();
                this.setState({ loading: false });
            }
        );
    };

    onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        this.setState({ recaptcha: value });
    };

    render() {
        return (
            <div className="mb-5">
                <div className="center mt-5 mb-3">
                    <img src="/banner-starplace.png" width="400" alt="" className="center" />
                </div>

                <div className="margin-auto create-account-card card mt-4 p-4">
                    <div className="w-75">
                        <h4 className="center mb-5 mt-3">Création de votre compte Starplace</h4>

                        <form className="" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                            <Field
                                name={FIELDS.organization}
                                type="text"
                                component={renderField}
                                label="Raison sociale ou nom de votre organisation"
                                className=""
                            />

                            <Field
                                name={FIELDS.addressLine1}
                                type="text"
                                component={renderField}
                                label="Adresse (administrative et de facturation)"
                                className=""
                            />
                            <Field
                                name={FIELDS.addressLine2}
                                type="text"
                                component={renderField}
                                label="Complément d'adresse"
                                className=""
                            />
                            <div className=" flex-row">
                                <div className="flex2 mr-2">
                                    <Field
                                        name={FIELDS.postalCode}
                                        type="text"
                                        component={renderField}
                                        label="Code postal"
                                    />
                                </div>
                                <div className="flex4">
                                    <Field
                                        name={FIELDS.city}
                                        type="text"
                                        component={renderField}
                                        label="Ville"
                                    />
                                </div>
                            </div>
                            <div className="mb-1">Pays</div>
                            <CountrySelect name={FIELDS.countryCode} className="flex1" />

                            <Field name="errorCountry" component={renderError} />
                            <div className="center mt-5 ">
                                <hr width="100%" />
                            </div>
                            <div className="contact-title center mt-4 mb-4">
                                Personne administrant le service Starplace
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        name={FIELDS.contactLastName}
                                        type="text"
                                        component={renderField}
                                        label="Nom"
                                    />
                                </div>
                                <div className="col">
                                    <Field
                                        name={FIELDS.contactFirstName}
                                        type="text"
                                        component={renderField}
                                        label="Prénom"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <Field
                                        name={FIELDS.email}
                                        type="text"
                                        component={renderField}
                                        label="Email de contact"
                                    />
                                </div>
                                <div className="col">
                                    <Field
                                        name={FIELDS.phone}
                                        type="text"
                                        component={renderField}
                                        label="Téléphone de contact"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        name={FIELDS.password}
                                        type="password"
                                        component={renderField}
                                        label="Mot de passe"
                                    />
                                </div>
                                <div className="col">
                                    <Field
                                        name={FIELDS.passwordcheck}
                                        type="password"
                                        component={renderField}
                                        label="Mot de passe (vérification)"
                                    />
                                </div>
                            </div>
                            <div className="flex-center mb-3 mt-3">
                                <ReCAPTCHA
                                    sitekey="6LerjsIZAAAAAIKEI7cDEJGvZyHjgnybk_hlVdG3"
                                    onChange={this.onCaptchaChange}
                                />
                            </div>

                            <div className="row justify-content-md-center">
                                <div className="">
                                    <Errors />
                                </div>
                            </div>
                            <div className="center mt-4 mb-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={this.state.loading}>
                                    <span
                                        className={this.getLoadingClassName()}
                                        role="status"
                                        aria-hidden="true"></span>
                                    Créer mon compte
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="center mt-4 mb-5">
                    <div>
                        <a href="/">Retour à la page de connexion</a>
                    </div>
                </div>
            </div>
        );
    }
}
const signupForm = reduxForm({
    form: "signup",
    fields: Object.keys(FIELDS),
    intialValues: {
        country: {
            label: "France",
            value: "FRA",
        },
    },
    validate: validateCustomer,
})(Signup);
export default connect(null, actions)(signupForm);
