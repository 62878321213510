import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Errors from "./Errors";

import * as actions from "../actions";

const FIELDS = { email: "email", password: "password" };

class Signin extends Component {
    handleSubmit = (credentials) => {
        if (
            credentials.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(credentials.email)
        ) {
            console.log("invalid email");
            this.props.displayError("Email invalide");
            return;
        }
        this.props.signinUser(credentials);
    };

    createCustomer = (e) => {
        e.preventDefault();
        this.props.history.push("/create");
    };

    render() {
        return (
            <div className="flex-center">
                <div className="center mt-5 mb-3">
                    <img src="/banner-starplace.png" width="400" alt="" className="center" />
                </div>
                <div className="center">
                    <div style={{ maxWidth: 550 }}>
                        <Errors />
                    </div>
                </div>

                <div className="">
                    <form
                        style={{ maxWidth: 550 }}
                        onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <h2 className="center m-3">Connexion</h2>

                        <div className="mb-3 mt-5">
                            <fieldset className="form-group">
                                <Field
                                    name={FIELDS.email}
                                    component="input"
                                    type="text"
                                    className="form-control"
                                    placeholder="Entrez votre email"
                                />
                            </fieldset>
                        </div>
                        <div className="mb-2">
                            <fieldset className="form-group">
                                <Field
                                    name={FIELDS.password}
                                    component="input"
                                    type="password"
                                    className="form-control"
                                    placeholder="Entrez votre mot de passe"
                                />
                            </fieldset>
                        </div>
                        <div className="center">
                            <button type="submit" className="btn btn-primary btn-raised w-100">
                                Connexion
                            </button>
                        </div>
                        <div className="center mt-4 mb-3">
                            <div style={{ color: "#777" }}>
                                Mot de passe oublié ?{" "}
                                <a
                                    style={{ color: "#777", textDecoration: "underline" }}
                                    href="/forgotpassword">
                                    cliquez-ici
                                </a>
                            </div>
                        </div>

                        <div className="center mt-4 mb-3">
                            <div>Vous n'avez pas encore de compte ?</div>
                            <div>
                                <a onClick={this.createCustomer} href="/">
                                    <strong>Créer un compte</strong>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
const signinForm = reduxForm({
    form: "signin",
    fields: Object.keys(FIELDS),
})(Signin);
export default connect(null, actions)(signinForm);
