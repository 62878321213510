import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Field, reduxForm } from "redux-form";
import Errors from "./Errors";
import CountrySelect from "./CountrySelect";
import validateCustomer from "./validateCustomer";
import renderField from "./renderField";
import CONTRY_NAMES from "../misc/countryNames";

const FIELDS = {
    addressLine1: "addressLine1",
    addressLine2: "addressLine2",
    city: "city",
    contactFirstName: "contactFirstName",
    contactLastName: "contactLastName",
    countryCode: "countryCode",
    email: "email",
    organization: "organization",
    phone: "phone",
    postalCode: "postalCode",
};

const renderError = ({ meta: { touched, error } }) => {
    return (
        <div className="mb-4">
            {touched && error && <span className="invalid-feedback d-block">{error}</span>}
        </div>
    );
};

class CustomerEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.props.initialize(this.props.customer);
    }

    getLoadingClassName = () => {
        if (this.state.loading) {
            return "spinner-border spinner-border-sm mr-2";
        } else {
            return "d-none";
        }
    };

    handleSubmit = (data) => {
        let values = { ...data };
        // small hack to store both country code and country
        values.country = CONTRY_NAMES[data.countryCode];

        this.setState({ loading: true }, () => {
            this.props.updateCustomer(
                values,
                () => {
                    console.log("updated ....");
                    this.setState({ loading: false });
                    this.props.stopEdition();
                },
                () => {
                    this.setState({ loading: false });
                }
            );
        });
    };

    stopEdition = () => {
        this.props.stopEdition();
    };

    render() {
        return (
            <div style={{ maxWidth: 550 }} className="margin-auto card  py-2 px-4">
                <div className="">
                    <div className="section-title mb-4">Modification de vos informations</div>
                    <form className="" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <Field
                            name={FIELDS.organization}
                            type="text"
                            component={renderField}
                            label="Raison sociale ou nom de votre organisation"
                            className=""
                        />

                        <Field
                            name={FIELDS.addressLine1}
                            type="text"
                            component={renderField}
                            label="Adresse (administrative et de facturation)"
                            className=""
                        />
                        <Field
                            name={FIELDS.addressLine2}
                            type="text"
                            component={renderField}
                            label="Complément d'adresse"
                            className=""
                        />
                        <div className=" flex-row">
                            <div className="flex2 mr-2">
                                <Field
                                    name={FIELDS.postalCode}
                                    type="text"
                                    component={renderField}
                                    label="Code postal"
                                />
                            </div>
                            <div className="flex4">
                                <Field
                                    name={FIELDS.city}
                                    type="text"
                                    component={renderField}
                                    label="Ville"
                                />
                            </div>
                        </div>
                        <div className="mb-1">Pays</div>
                        <CountrySelect name={FIELDS.countryCode} className="flex1" />

                        <Field name="errorCountry" component={renderError} />
                        <div className="center mt-5 ">
                            <hr width="100%" />
                        </div>
                        <div className="contact-title center mt-4 mb-4">
                            Personne administrant le service Starplace
                        </div>
                        <div className="row">
                            <div className="col">
                                <Field
                                    name={FIELDS.contactLastName}
                                    type="text"
                                    component={renderField}
                                    label="Nom"
                                />
                            </div>
                            <div className="col">
                                <Field
                                    name={FIELDS.contactFirstName}
                                    type="text"
                                    component={renderField}
                                    label="Prénom"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Field
                                    name={FIELDS.email}
                                    type="text"
                                    component={renderField}
                                    label="Email de contact"
                                />
                            </div>
                            <div className="col">
                                <Field
                                    name={FIELDS.phone}
                                    type="text"
                                    component={renderField}
                                    label="Téléphone de contact"
                                />
                            </div>
                        </div>

                        <div className="row justify-content-md-center">
                            <div className="">
                                <Errors />
                            </div>
                        </div>
                        <div className="center mt-4 mb-4">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={this.state.loading}>
                                <span
                                    className={this.getLoadingClassName()}
                                    role="status"
                                    aria-hidden="true"></span>
                                Modifier mes informations
                            </button>
                        </div>
                        <div className="center mt-3">
                            <button onClick={this.stopEdition} className="btn btn-link ">
                                Annuler
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const customerEditForm = reduxForm({
    form: "customerEdit",
    fields: Object.keys(FIELDS),
    intialValues: {
        country: {
            label: "France",
            value: "FRA",
        },
    },
    validate: validateCustomer,
})(CustomerEdit);

const mapStateToProps = (state) => {
    return {
        customer: state.customer.current,
    };
};
export default connect(mapStateToProps, actions)(customerEditForm);
