import React, { Component } from "react";
import { connect } from "react-redux";
import Errors from "./Errors";
import queryString from "query-string";
import axios from "axios";
import CONSTANTS from "../constants";
import { displayError, signinWithToken } from "../actions";

class Verify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verifyToken: null,
            status: "idle",
            message: "",
        };
    }
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        this.setState({ verifyToken: parsed.id }, () => {
            this.verifyToken(
                parsed.id,
                (code, authenticationToken, customerId) => {
                    console.log("Is verified: ", code);
                    if (code === "ERROR_TOKEN_EXPIRED" || code === "ERROR_CUSTOMER_NOT_FOUND") {
                        this.setState({
                            status: "unvalid",
                            message: "Désolé, il semble que ce lien ne soit plus valide.",
                        });
                    } else {
                        this.setState(
                            {
                                status: "verified",
                                message:
                                    "Félicitations, votre compte est activé. Vous pouvez commencer à configurer votre première Starplace.",
                            },
                            () => {
                                // success : store Token
                                this.props.signinWithToken(customerId, authenticationToken);
                            }
                        );
                    }
                },
                () => {
                    this.setState({ message: "", status: "idle" });
                }
            );
        });
    }

    verifyToken = async (verifyToken, successCallback, errorCallback) => {
        if (!verifyToken) {
            return;
        }

        try {
            // RETRIEVE THE S3 SIGNED URL to upload picture
            const serverURL = CONSTANTS.VERIFY_CUSTOMER_TOKEN_URL + verifyToken;
            console.log("TRY TO VERIFY CUSTOMER, URL : ", serverURL);

            let response = await axios({ method: "get", url: serverURL });
            if (!response) {
                throw new Error("ERROR during verify");
            }
            if (successCallback)
                successCallback(response.data.code, response.data.token, response.data.customerId);
        } catch (error) {
            console.log("Erreur serveur : ", error);
            this.props.displayError(
                "Une erreur serveur est survenue, veuillez réessayer plus tard.",
                0
            );
            if (errorCallback) errorCallback(error);
        }
    };

    goToStarplace = () => {
        this.props.history.push("/");
    };

    render() {
        const { status, message } = this.state;
        return (
            <div>
                <div className="center mt-5 mb-3">
                    <img src="/banner-starplace.png" width="400" alt="" className="center" />
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4">
                        <Errors />
                    </div>
                </div>

                <div className="row justify-content-md-center mt-5">{message}</div>

                {status === "verified" && (
                    <div className="center mt-5 mb-4">
                        <button
                            onClick={this.goToStarplace}
                            type="submit"
                            className="btn btn-primary">
                            Accéder à Starplace
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(null, { displayError, signinWithToken })(Verify);
