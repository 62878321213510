import React, { Component } from "react";
import SubHeader from "../components/SubHeader";
import PlaceView from "../components/PlaceView";
import PlaceStats from "../components/PlaceStats";
import PlaceEdit from "../components/PlaceEdit";
import PlaceSubscription from "../components/PlaceSubscription";
import { Route, Switch } from "react-router-dom";

class PlaceRoutes extends Component {
    render() {
        return (
            <div>
                <SubHeader />
                <div className="container">
                    <Switch>
                        <Route path="/places/view" component={PlaceView} />
                        <Route path="/places/stats" component={PlaceStats} />
                        <Route path="/places/edit" component={PlaceEdit} />
                        <Route path="/places/subscription" component={PlaceSubscription} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default PlaceRoutes;
