import React, { Component } from "react";
import { ZMap, ZLatLng, ZGeoCoder, ZPolygon, ZMarker } from "../misc/mapwrapper-google";

// test poly
import * as PolyUtils from "../misc/poly-utils";

const MAX_NUMBER_OF_ZONES = 1;
const ZOOM_MIN_LEVEL = 6;

class MapDisplay extends Component {
    constructor(props) {
        super(props);
        this.map = null;
        this.geocoder = null;
        this.polyTab = []; // working structure to convert in zoneList (initial display convert zoneList to polyTab)
        this.zoneList = []; // tab of zone, a zone is {type: "poly", id:"idpoly", data: [[lat,lng]]}, data is a tab of GPS point
        this.tmpMarkers = [];
        this.marker = null; // GPS coordinate of site
        this.gps = [];
        this.zoom = ZOOM_MIN_LEVEL; // by default set to 6
        this.polyIndex = -1; // used for current poly selection
        this.oldindex = -1; // to restore old color when poly is unselected
        this.suspendSelection = false;
        this.edition = false;
        this.creation = false;
        this.creationListener = null;
        this.initialLatLng = null;
        this.isInitialized = false;
        this.setgps = false;

        this.state = {
            syncToogle: false,
            showCancel: false,
            showValidate: false,
            showCreate: true,
            showEdit: true,
            showDelete: true,
            disabledCreation: false,
            showSearch: true,
        };
    }

    componentDidMount() {
        console.log("componentDidMount, this.props.boundaries: ", this.props.boundaries);
        this.initData();
        this.initMap();
        setTimeout(() => {
            this.initDisplay();
        }, 1000);
    }

    initData() {
        //console.log("boundaries: ", this.props.boundaries);
        this.zoneList = this.props.boundaries;
        this.zoom = this.props.zoom;
        this.gps = this.props.gps;

        /*[
            {
                type: "poly",
                id: "poly1",
                data: [
                    [48.2113628, 1.0752868],
                    [48.3575759, 4.2393493],
                    [47.1462556, 2.7452087],
                    [48.2113628, 1.0752868],
                ],
            },
            {
                type: "poly",
                id: "poly2",
                data: [
                    [48.1781088, 4.961061],
                    [48.789792, 7.4659438],
                    [48.0974609, 7.707643],
                    [47.5145371, 6.6749282],
                    [48.1781088, 4.961061],
                ],
            },
        ];*/
    }

    initMap() {
        if (this.map === null) {
            this.map = new ZMap(document.getElementById("myMap"));
            this.map.setCenter(new ZLatLng(47.0824, 2.3985));
            this.geocoder = new ZGeoCoder();
        }
    }

    initDisplay = () => {
        this.updateButtons();

        // backup selection id
        let currentselectionid = null;
        if (this.polyTab.length > 0) {
            currentselectionid = this.polyTab[this.polyIndex].id;
        }

        this.removeAllPoly();
        this.displayPoly();

        this.cleanTheNote();
        ///// recenter map on suitable position with correct zoom
        if (this.zoom > ZOOM_MIN_LEVEL) {
            this.map.setZoom(this.zoom);
        }

        if (this.gps.length > 0) {
            const latlng = new ZLatLng(this.gps[0], this.gps[1]);
            this.marker = new ZMarker({
                position: latlng,
                map: this.map,
            });
            this.map.setCenter(latlng);
        }
        //////////

        // restore the selection
        this.oldindex = -1;
        let i = -1;
        if (this.zoneList.length > 0) {
            if (currentselectionid !== null) {
                for (i = 0; i < this.polyTab.length; i++) {
                    if (this.polyTab[i].id === currentselectionid) {
                        break;
                    }
                }
            }
        }
        if (!this.props.readOnly) {
            this.selectPoly(i);
        }
    };

    selectPoly(i) {
        if (i < 0) return;
        if (this.suspendSelection) {
            return;
        }
        console.log("select poly index: ", i);
        if (this.oldindex >= 0) {
            // restore suitable color
            this.polyTab[this.oldindex].gmp.setOptions({
                fillColor: this.getPolyColor(),
            });
        }

        this.polyTab[i].gmp.setOptions({ fillColor: "rgb(255, 246, 24)" });
        this.oldindex = i;
        this.polyIndex = i;
    }

    removeAllPoly() {
        for (let i = 0; i < this.polyTab.length; i++) {
            const item = this.polyTab[i];

            item.gmp.remove();
            item.gmp.removeListener(item.listener);
            this.polyTab[i] = null;
        }
        this.polyTab = [];
        this.polyIndex = 0;
    }

    displayPoly() {
        let num = 0;
        for (let i = 0; i < this.zoneList.length; i++) {
            // create coordinates tables
            let tabmaster = [];

            for (let j = 0; j < this.zoneList[i].data.length; j++) {
                tabmaster.push(
                    new ZLatLng(this.zoneList[i].data[j][0], this.zoneList[i].data[j][1])
                );
            }

            // now display polygon
            if (tabmaster.length > 1) {
                (function (i, that, tabmaster, num) {
                    const masterPoly = that.createPoly(
                        tabmaster,
                        1,
                        that.getStrokeColor(),
                        that.getPolyColor(),
                        that.getPolyOpacity()
                    );

                    const idzone = that.zoneList[i].id;

                    const poly = {
                        id: idzone,
                        index: i,
                        //data: tabmaster,
                        gmp: masterPoly,
                        listener: masterPoly.addListener("click", () => {
                            //console.log("display num callback: " + num);
                            if (!that.props.readOnly) {
                                that.selectPoly(num);
                            }
                        }),
                    };
                    poly.gmp.setMap(that.map);
                    that.polyTab.push(poly);
                })(i, this, tabmaster, num);
                num++;
            }
        }
    }

    createPoly(tab, thickness, color, fillcolor, filltransparency) {
        if (tab === null) return null;

        let poly = new ZPolygon(this.map, {
            strokeColor: this.getStrokeColor(-1),
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: this.getPolyColor(-1),
            fillOpacity: this.getPolyOpacity(-1),
        });
        if (tab && tab != null) {
            poly.setPaths(tab);
        }
        if (thickness && thickness != null) {
            poly.setOptions({ strokeWeight: thickness });
        }
        if (color && color != null) {
            poly.setOptions({ strokeColor: color });
        }
        if (fillcolor && fillcolor != null) {
            poly.setOptions({ fillColor: fillcolor });
        }
        if (filltransparency && filltransparency != null) {
            poly.setOptions({ fillOpacity: filltransparency });
        }

        poly.setMap(this.map);
        return poly;
    }

    getPolyColor() {
        // return "rgb(17, 17, 17)";
        return "rgb(255,0,255)";
    }

    getStrokeColor() {
        //  return "#000000";
        return "#FF0000";
    }

    getPolyOpacity() {
        return 0.2;
    }

    updateButtons() {
        console.log("UPDATE BUTTON", this.zoneList.length);

        this.showCreateBtn();
        if (this.zoneList.length > 0) {
            this.showEditBtn();
            this.showDeleteBtn();
        } else {
            this.hideEditBtn();
            this.hideDeleteBtn();
        }
    }

    showCreateBtn() {
        if (this.zoneList.length >= MAX_NUMBER_OF_ZONES) {
            this.setState({ disabledCreation: true });
        }
        this.setState({ showCreate: true });
    }
    hideCreateBtn() {
        this.setState({ showCreate: false });
    }

    showEditBtn() {
        this.setState({ showEdit: true });
    }
    hideEditBtn() {
        this.setState({ showEdit: false });
    }

    showCancelBtn() {
        this.setState({ showCancel: true });
    }
    hideCancelBtn() {
        this.setState({ showCancel: false });
    }
    showValidateBtn() {
        this.setState({ showValidate: true });
    }

    hideValidateBtn() {
        this.setState({ showValidate: false });
    }

    showShearchBtn() {
        this.setState({ showSearch: true });
    }

    hideSearchBtn() {
        this.setState({ showSearch: false });
    }

    showDeleteBtn() {
        this.setState({ showDelete: true });
    }

    hideDeleteBtn() {
        this.setState({ showDelete: false });
    }

    delete = (event) => {
        event.preventDefault();
        if (this.polyIndex < 0) return;

        console.log("try to delete index ", this.polyIndex);
        if (this.polyTab.length > 0) {
            // remove from polytab
            const item = this.polyTab[this.polyIndex];
            if (item === null) return;
            item.gmp.removeListener(item.listener);
            item.gmp.remove();
            // DONT REMOVE ENTRY DUE TO LISTENER OF OTHER ENTRIES WHICH USED PREDEFINED INDEX !!!
            this.polyTab[this.polyIndex] = null;

            // remove from zoneList using id stored in poly tab
            for (let i = 0; i < this.zoneList.length; i++) {
                if (this.zoneList[i] && this.zoneList[i].id === item.id) {
                    this.zoneList.splice(i, 1);
                    break;
                }
            }

            this.setState({ disabledCreation: false });
            // set new polyindex to available
            this.findAvailableIndex();

            this.selectPoly(this.polyIndex);
        }

        this.updateButtons();
    };

    edit = (event) => {
        event.preventDefault();
        if (this.polyIndex < 0) {
            return;
        }

        this.hideEditBtn();
        this.hideCreateBtn();
        this.hideDeleteBtn();

        this.edition = true;
        this.activateEdition();
        // make the the poly editable
        const polygon = this.polyTab[this.polyIndex].gmp;
        polygon.setEditable(true);
        polygon.setDraggable(true);
        this.initialLatLng = polygon.getPath().getAt(0);
    };

    activateEdition() {
        this.suspendSelection = true;

        if (this.creation === true) {
            this.showCancelBtn();
        } else {
            this.hideCancelBtn();
        }
        this.showValidateBtn();
    }

    cancel = (event) => {
        event.preventDefault();

        if (this.setgps) {
            this.showCreateBtn();
            this.showEditBtn();
            this.showDeleteBtn();
            this.showShearchBtn();
            this.hideCancelBtn();
            this.setgps = false;
            this.map.setOptions({ draggableCursor: "" });
            if (this.creationListener !== null) {
                console.log("remove listener");
                this.map.removeListener(this.creationListener);
            }
            this.allVisible();
            return;
        }
        this.validate(null, true);
    };

    validate = (event, pcancel) => {
        if (event) event.preventDefault();
        let cancel = false;
        if (pcancel) {
            cancel = pcancel;
        } else {
            this.zoom = this.map.getZoom();
        }

        this.map.setOptions({ draggableCursor: "" });

        if (this.creationListener !== null) {
            cancel = cancel || this.polyTab[this.polyTab.length - 1].gmp.getPath().getLength() < 3;
            this.finishCreation(cancel);
        }

        // in case of edit
        console.log("DEBUG TOTO1");
        if (/*this.polyIndex > 0 &&*/ this.polyTab[this.polyIndex]) {
            console.log("DEBUG TOTO2");
            let polygon = this.polyTab[this.polyIndex].gmp;
            polygon.setDraggable(false);
            polygon.setEditable(false);
        }

        if (!cancel) {
            // convert only if creation is possible
            this.convertPolyToData();
        }

        this.suspendSelection = false;
        this.creation = false;
        this.edition = false;

        this.initialLatLng = null;

        this.hideCancelBtn();
        this.hideValidateBtn();
        this.updateButtons();

        // remove Marker
        for (let i = 0; i < this.tmpMarkers.length; i++) {
            this.tmpMarkers[i].remove();
            this.tmpMarkers[i] = null;
        }
        this.tmpMarkers = [];

        this.selectPoly(this.polyIndex);

        // call onchange callback
        if (this.props.onChange) {
            this.props.onChange(this.zoneList, this.gps, this.zoom);
        }
    };

    polyToData(gmp) {
        let tab = [];
        gmp.getPath().update();
        let vertices = gmp.getPath();
        for (let i = 0; i < vertices.getLength(); i++) {
            tab.push([
                this.limitSize(vertices.getAt(i).lat()),
                this.limitSize(vertices.getAt(i).lng()),
            ]);
        }
        // add again the first only, if not already done
        if (
            vertices.getAt(0).lat() !== vertices.getAt(vertices.getLength() - 1).lat() &&
            vertices.getAt(0).lng() !== vertices.getAt(vertices.getLength() - 1).lng()
        ) {
            tab.push([
                this.limitSize(vertices.getAt(0).lat()),
                this.limitSize(vertices.getAt(0).lng()),
            ]);
        }
        // gmp.getPath().update();

        return tab;
    }

    limitSize(value) {
        const factor = 10000000; // number of 0 will be the precision
        const newval = Math.floor(value * factor) / factor;
        //console.log(newval + ' from ' + val);
        return newval;
    }

    finishCreation(cancel) {
        console.log("finish creation");
        if (this.creationListener !== null) {
            console.log("remove listener");
            this.map.removeListener(this.creationListener);
            const size = this.polyTab[this.polyTab.length - 1].gmp.getPath().getLength();
            console.log("number of points is: " + size);
            if (size < 3 || cancel) {
                console.log("cancel creation of poly");
                // not enought point to do a polygon, so cancel it
                this.polyTab[this.polyTab.length - 1].gmp.remove();
                this.polyTab.pop();
                //this.tree.pop();
                this.findAvailableIndex();
            } else {
                this.polyIndex = this.polyTab.length - 1;
            }
            this.creationListener = null;
            this.allVisible();
        }
    }

    findAvailableIndex = () => {
        this.polyIndex = -1;
        this.oldindex = -1;
        for (let i = 0; i < this.polyTab.length; i++) {
            if (this.polyTab[i] !== null) {
                this.polyIndex = i;
                break;
            }
        }
    };

    convertPolyToData() {
        console.log("convertPolyToData for polyindex: " + this.polyIndex);

        const item = this.polyTab[this.polyIndex];
        if (item) {
            // create zone and add it the the zoneList OR update in case of edition
            const zone = { type: "poly", id: item.id, data: this.polyToData(item.gmp) };
            if (this.edition === true) {
                console.log("BEFORE ", this.zoneList.length);
                for (let i = 0; i < this.zoneList.length; i++) {
                    if (this.zoneList[i].id === item.id) {
                        this.zoneList[i] = zone;
                        break;
                    }
                }
                console.log("AFTER ", this.zoneList.length);
            } else {
                this.zoneList.push(zone);
                console.log("created zone : ", zone);
            }
        }
    }

    allVisible() {
        for (let i = 0; i < this.polyTab.length; i++) {
            if (this.tmpvisible && this.tmpvisible != null && this.polyTab[i]) {
                this.polyTab[i].gmp.setVisible(this.tmpvisible[i]);
            }
        }
        this.tmpvisible = null;
    }

    allInvisible() {
        this.tmpvisible = [];
        // copy the state of visibility to restore it
        for (let i = 0; i < this.polyTab.length; i++) {
            if (this.polyTab[i]) {
                this.tmpvisible.push(this.polyTab[i].gmp.getVisible());
                this.polyTab[i].gmp.setVisible(false);
            }
        }
    }

    create = (event) => {
        event.preventDefault();
        //this.cleanTheNote();
        this.hideCreateBtn();
        this.hideEditBtn();
        this.hideDeleteBtn();
        if (this.creation) {
            // a creation is already in progress. Creation is not possible in edit plus mode
            return;
        }

        // to signal creation is in progress
        this.creation = true;
        this.map.setOptions({ draggableCursor: "crosshair" });

        // disable other selection
        this.activateEdition();

        // Make all invisible
        this.allInvisible();

        // create polygon
        const myMapPoly = new ZPolygon(this.map, {
            //paths: [], // no point at creation
            //draggable: true,
            //editable: true,
            strokeColor: this.getStrokeColor(-1),
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: this.getPolyColor(-1),
            fillOpacity: this.getPolyOpacity(-1),
        });

        // add to tree the empty zone
        //this.zoneList.length;
        const newid = "id-" + new Date().getTime();
        // push empty zone in TREE HERE !!!

        // create working structure
        (function (newid, that, num) {
            const poly = {
                id: newid,
                index: that.zoneList.length,
                gmp: myMapPoly,
                listener: myMapPoly.addListener("click", () => {
                    console.log("num in callback: " + num);
                    that.selectPoly(num);
                }),
            };
            console.log("add listener to poly with num: " + num);
            console.log(poly.listener);

            poly.gmp.setMap(that.map);
            that.polyTab.push(poly);
        })(newid, this, this.polyTab.length);

        // add a click listener for points creation
        this.creationListener = this.map.addListener("click", this.addLatLng);
    };

    addLatLng = (event) => {
        console.log("add point");
        const path = this.polyTab[this.polyTab.length - 1].gmp.getPath();

        const latLng = this.map.getEventLatLng(event);
        this.tmpMarkers.push(
            new ZMarker({
                position: latLng,
                map: this.map,
                icon: {
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                },
            })
        );

        // Because path is an MVCArray, we can simply append a new coordinate
        // and it will automatically appear.
        path.push(latLng);
    };

    debug = (event) => {
        event.preventDefault();
        console.log("####### zoneList #######");
        for (let i = 0; i < this.zoneList.length; i++) {
            console.log(this.zoneList[i]);
            console.log("-----------------------");
        }
        console.log("####### Poly Tab #######");
        for (let i = 0; i < this.polyTab.length; i++) {
            console.log(this.polyTab[i]);
            console.log("-----------------------");
        }
    };

    locateAddress = (event) => {
        event.preventDefault();
        window.$("#addressModal").modal("show");
    };

    cleanTheNote = () => {
        if (this.marker != null) {
            this.marker.remove();
            this.marker = null;
            this.notes = [];
        }
    };

    findAddressPoint = () => {
        this.cleanTheNote();
        console.log("code address");
        const address = window.$("#address-point").val();
        this.geocoder.geocode(address, (results, status) => {
            if (this.geocoder.getValueFromStatus(status) === this.geocoder.status.OK) {
                window.$("#addressModal").modal("hide");
                let loc = this.geocoder.getValueFromResult(results);

                this.map.setZoom(17);
                this.map.setCenter(loc);
                this.marker = new ZMarker({
                    position: loc,
                    map: this.map,
                });

                this.marker.setAnimation("bounce");
                setTimeout(() => {
                    this.marker.setAnimation(null);
                    setTimeout(() => {
                        this.confirmGPSPoint();
                    }, 3000);
                }, 1000);
            } else {
                alert(
                    "Adresse invalide " //+ this.geocoder.getErrorMessage(status)
                );
            }
        });
    };

    confirmGPSPoint = () => {
        window.$("#confirmGPS").modal("show");
    };

    validateGPS = () => {
        if (this.marker) {
            const position = this.marker.getPosition();
            this.gps = [position.lat(), position.lng()];
            this.zoom = this.map.getZoom();
            // call onchange callback
            if (this.props.onChange) {
                this.props.onChange(this.zoneList, this.gps, this.zoom);
            }
        }
        window.$("#confirmGPS").modal("hide");
    };

    setGPSPoint = () => {
        //this.cleanTheNote();
        this.hideCreateBtn();
        this.hideValidateBtn();
        this.hideDeleteBtn();
        this.hideEditBtn();
        this.hideSearchBtn();
        this.showCancelBtn();
        this.setgps = true;
        this.map.setOptions({ draggableCursor: "crosshair" });

        // Make all invisible
        this.allInvisible();

        this.creationListener = this.map.addListener("click", (event) => {
            this.map.setOptions({ draggableCursor: "" });
            this.cleanTheNote();
            this.zoom = this.map.getZoom();
            const latlng = this.map.getEventLatLng(event);
            this.gps = [latlng.lat(), latlng.lng()];

            ///// only for debug
            /* console.log(
                "######## IS INSIDE POLY : ",
                PolyUtils.isMyLocationIsInStarplace(
                    { latitude: this.gps[0], longitude: this.gps[1] },
                    this.zoneList[0].data
                )
            );*/
            /////////
            this.marker = new ZMarker({
                position: this.map.getEventLatLng(event),
                map: this.map,
            });
            // call onchange callback
            if (this.props.onChange) {
                this.props.onChange(this.zoneList, this.gps, this.zoom);
            }
            this.showCreateBtn();
            this.showEditBtn();
            this.showDeleteBtn();
            this.showShearchBtn();
            this.hideCancelBtn();
            this.setgps = false;
            if (this.creationListener !== null) {
                console.log("remove listener");
                this.map.removeListener(this.creationListener);
            }
            this.allVisible();
        });
    };

    render() {
        return (
            <div className="w-100">
                {!this.props.readOnly && (
                    <div>
                        <div className="center">
                            {!this.state.showCancel &&
                                !this.state.showValidate &&
                                this.state.showSearch && (
                                    <button
                                        type="button"
                                        onClick={this.locateAddress}
                                        className="btn btn-sm btn-outline-success mb-3 mr-2">
                                        Trouver un lieu
                                    </button>
                                )}
                            {!this.state.showCancel && !this.state.showValidate && (
                                <button
                                    type="button"
                                    onClick={this.setGPSPoint}
                                    className="btn btn-sm btn-outline-primary mb-3 mr-2">
                                    <span>GPS </span>
                                    <i
                                        style={{ fontSize: "16px", verticalAlign: "middle" }}
                                        className="material-icons">
                                        my_location
                                    </i>
                                </button>
                            )}
                            {this.state.showCreate && (
                                <button
                                    disabled={this.state.disabledCreation}
                                    type="button"
                                    onClick={this.create}
                                    className="btn btn-sm btn-outline-primary mb-3 mr-2">
                                    Créer une zone Starplace
                                </button>
                            )}
                            {this.state.showEdit && (
                                <button
                                    type="button"
                                    onClick={this.edit}
                                    className="btn btn-sm btn-outline-primary mb-3 mr-2">
                                    Modifier la zone
                                </button>
                            )}
                            {this.state.showDelete && (
                                <button
                                    type="button"
                                    onClick={this.delete}
                                    className="btn btn-sm btn-outline-primary mb-3 mr-2">
                                    Supprimer la zone
                                </button>
                            )}
                            {this.state.showValidate && (
                                <button
                                    type="button"
                                    onClick={this.validate}
                                    className="btn btn-sm btn-outline-danger mb-3 mr-2">
                                    Valider
                                </button>
                            )}
                            {this.state.showCancel && (
                                <button
                                    type="button"
                                    onClick={this.cancel}
                                    className="btn btn-sm btn-outline-secondary mb-3 mr-2">
                                    Annuler
                                </button>
                            )}
                        </div>

                        <div
                            className="modal fade"
                            id="addressModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="label2"
                            aria-hidden="true">
                            <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="label2">
                                            Pointer une addresse
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <fieldset className="form-group mt-3 mb-3">
                                            <label className="bmd-label-floating mb-1">
                                                Adresse :
                                            </label>
                                            <div>
                                                <input
                                                    name="address-point"
                                                    id="address-point"
                                                    className="form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </fieldset>
                                        <div className="form-group center">
                                            <button
                                                type="button"
                                                onClick={this.findAddressPoint}
                                                className="btn btn-primary">
                                                Trouver le lieu
                                            </button>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-default"
                                            data-dismiss="modal">
                                            Fermer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="modal fade"
                            id="confirmGPS"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="label3"
                            aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <h5 className="m-3">
                                            Définir les coordonnées GPS de votre site à partir de ce
                                            point ?
                                        </h5>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal">
                                            NON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={this.validateGPS}
                                            className="btn btn-primary">
                                            OUI
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div
                    id="myMap"
                    className="center"
                    style={{ height: "500px", margin: "auto" }}></div>
            </div>
        );
    }
}

export default MapDisplay;
