import {
    SET_CUSTOMER,
    SET_AUTHENTIFICATION,
    SET_AUTHENTIFICATION_TOKEN,
    RESET_CUSTOMER,
} from "../actions/action-types";

export default function CustomerIdReducer(
    state = { current: null, isAuthenticated: false, token: null },
    action
) {
    switch (action.type) {
        case RESET_CUSTOMER:
            return {
                token: null,
                current: null,
                isAuthenticated: false,
            };
        case SET_AUTHENTIFICATION:
            console.log("SET AUTHENTICATION REDUCER : ", action.payload);
            return {
                ...state,
                isAuthenticated: action.payload,
            };

        case SET_AUTHENTIFICATION_TOKEN:
            return {
                ...state,
                token: action.payload,
            };

        case SET_CUSTOMER:
            return {
                ...state,
                current: action.payload,
            };
        default:
            return state;
    }
}
