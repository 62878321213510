import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import renderField from "./renderField";

const renderError = ({ meta: { touched, error } }) => {
    return (
        <div className="mb-4">
            {touched && error && <span className="invalid-feedback d-block">{error}</span>}
        </div>
    );
};

const PlaceFormPage5 = (props) => {
    const { handleSubmit, previousPage, initialValues, loading, editMode, exitCallback } = props;

    useEffect(() => {
        // INIT VALUE HERE WITH THE ONES LOADED
        //props.dispatch(change("wizard", "tel", "phone number"));
        // TODO : ATTENTION on doit mettre un flag dans le state global pour eviter de recharger les données
        // tant qu'on a pas fait un submit ou une sauvegarde !!!! sinon les données seront écrasées si on
        // revient en arriere dans le wizard
        if (initialValues) {
            props.initialize(initialValues);
        }
    }, [initialValues]);

    return (
        <div className="row justify-content-md-center">
            <h4 className="center mb-4">Paramètres vidéo</h4>

            <form className="col-md-11" onSubmit={handleSubmit}>
                <p className="center text-danger background-light ml-5 mr-5 mb-4">
                    Attention : Ces paramètres ont une incidence directe sur la capacité de stockage
                    des vidéos et sur leur capacité de délivrabilité.
                </p>

                <Field
                    name="retentionDuration"
                    type="number"
                    component={renderField}
                    min={0}
                    max={48}
                    label="Durée de rétention des vidéos (en mois)"
                />
                <Field
                    name="recordMaxDuration"
                    type="number"
                    component={renderField}
                    min={0}
                    max={30}
                    label="Durée maximale des vidéos (en secondes)"
                />
                <Field
                    name="numberOfRecordPerUserPerDay"
                    type="number"
                    component={renderField}
                    min={0}
                    max={10}
                    label="Nombre maximum d'enregistrements par personne et par jour sur le lieu Starplace"
                />

                <label className="bmd-label-floating mb-1 d-block">
                    Résolution maximale des vidéos :
                </label>
                <Field
                    name="recordMaxResolution"
                    className="select-wizard-sl custom-select select-wizard-bg"
                    component="select">
                    <option>Sélectionner ...</option>
                    <option value="480">SD 480</option>
                    <option value="720">HD 720</option>
                </Field>
                <Field name="recordMaxResolution" component={renderError} />
                <Field
                    name="delayBeforeView"
                    type="number"
                    component={renderField}
                    min={0}
                    max={6}
                    label="Période de garde (Nombre d'heures avant qu'une vidéo enregistrée puisse être lue)"
                />

                <div className="mt-4">
                    <button type="button" className="btn btn-secondary mr-3" onClick={previousPage}>
                        Précédent
                    </button>
                    <button type="submit" className="btn btn-primary">
                        <span className={loading} role="status" aria-hidden="true"></span>
                        Sauvegarder et continuer
                    </button>

                    {editMode && (
                        <button
                            type="button"
                            onClick={exitCallback}
                            className="btn btn-link float-right">
                            Quitter l'édition
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default reduxForm({
    form: "wizard", //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(PlaceFormPage5);

/*
     <h5 className="">Paramètres principaux</h5>

                <h5 className="">Paramètres avancés</h5>
                <p className="text-secondary">
                    Vous pouvez laisser les valeurs par défaut sauf si vous souhaitez expressément
                    changer ces paramètres.
                </p>

                <Field
                    name="recordMaxSize"
                    type="number"
                    component={renderField}
                    min={0}
                    max={160}
                    label="Taille maximale (en Mo)"
                />

                <Field
                    name="numberOfDownloadPerUser"
                    type="number"
                    component={renderField}
                    min={0}
                    max={3}
                    label="Nombre maximum de téléchargements d'une vidéo par personne invitée"
                />


                <label className="bmd-label-floating mb-1 d-block">
                    Action après lecture par tous les invités :
                </label>


                <Field
                    name="recordPolicy"
                    className="select-wizard custom-select select-wizard-bg"
                    component="select">
                    <option>Selectionner ...</option>
                    <option value="remove-after-watched">Effacer</option>
                    <option value="wait-expiration">
                        Conserver pendant toute la durée de rétention
                    </option>
                </Field>
                <Field name="recordPolicy" component={renderError} />

*/
