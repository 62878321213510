import { combineReducers } from "redux";
import { reducer as ReducerForm } from "redux-form";
import CustomerReducer from "./customer-reducer";
import PlaceReducer from "./place-reducer";
import ErrorReducer from "./error-reducer";
import OfferReducer from "./offer-reducer";
import StatsReducer from "./stats-reducer";

import "bootstrap-icons/font/bootstrap-icons.css";

const rootReducer = combineReducers({
    form: ReducerForm,
    customer: CustomerReducer,
    place: PlaceReducer,
    errors: ErrorReducer,
    offers: OfferReducer,
    stats: StatsReducer,
    // customer:
    //currentPlace:
    //listOfPlace:
});

export default rootReducer;
