import React, { useEffect, useState } from "react";

import { reduxForm } from "redux-form";
import PictureSelector from "../containers/PictureSelector";
import validate from "./validate";

const PlaceFormPage3 = (props) => {
    const { previousPage, initialValues, loading, editMode, exitCallback } = props;
    let pictures = initialValues.pictures ? initialValues.pictures : [];

    function changeHandler(listOfPicture) {
        // do processing on picture here or update the Place picture
        console.log("changeHandler : ", listOfPicture);
        pictures = listOfPicture;
    }

    function handlePictures(data) {
        props.onSubmit(pictures);
    }

    return (
        <div className="row justify-content-md-center">
            <h4 className="center mb-4">Fiche descriptive : les images</h4>

            <div className="col-md-11">
                <PictureSelector
                    pictures={pictures}
                    placeId={initialValues.placeId}
                    onChange={changeHandler}
                />
                <div className=" mt-4">
                    <button type="button" className="btn btn-secondary mr-3" onClick={previousPage}>
                        Précédent
                    </button>
                    <button type="button" onClick={handlePictures} className="btn btn-primary">
                        <span className={loading} role="status" aria-hidden="true"></span>
                        Sauvegarder et continuer
                    </button>

                    {editMode && (
                        <button
                            type="button"
                            onClick={exitCallback}
                            className="btn btn-link float-right">
                            Quitter l'édition
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
export default reduxForm({
    form: "wizard", //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(PlaceFormPage3);
