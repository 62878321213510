import React from "react";
import AddCard from "./AddCard";
import PlaceItem from "./PlaceItem";

const PlaceList = ({ list, history, deletionCallback }) => {
    function createNewPlace(e) {
        e?.preventDefault();
        history.push("/wizard");
    }

    return (
        <div className="row">
            {list.map((item) => {
                const { name, sumup, thumbnail, placeId } = item;
                const pictureUri =
                    thumbnail && thumbnail !== "null" ? thumbnail : "/placeholder.png";
                console.log("pictureUri : ", pictureUri);
                return (
                    <div key={placeId} className="col-auto mb-4">
                        <PlaceItem
                            history={history}
                            title={name}
                            desc={sumup}
                            pictureUri={pictureUri}
                            placeId={placeId}
                            deletionCallback={deletionCallback}
                        />
                    </div>
                );
            })}
            <div className="col-auto mb-4">
                <AddCard
                    title="Ajouter un nouveau site"
                    subtitle="à vos lieux Starplace existants"
                    actionText="Créer un nouveau site"
                    actionUrl={createNewPlace}
                />
            </div>
        </div>
    );
};

export default PlaceList;
