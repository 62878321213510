import React, { useState } from "react";
import "../css/PictureMosaic.css";

const PictureMosaic = ({ pictures, big = false }) => {
    const firstBig = big;

    const [item, setItem] = useState({});

    function displayPictureData(event) {
        let index = event.target.getAttribute("index");
        if (index) {
            console.log("click picture ", pictures[index]);
            setItem({
                copyright: pictures[index].copyright,
                legend: pictures[index].legend,
                pictureUri: pictures[index].uri,
            });

            window.$("#picmodal").modal("show");
        }
    }

    function renderFirstBigPicture() {
        if (firstBig) {
            return (
                <div className="container">
                    <div className="mb-3 row">
                        <span className="helper"></span>
                        <div className="margin-auto">
                            <img
                                index={0}
                                onClick={displayPictureData}
                                className="main-picture"
                                src={pictures[0].uri}
                                alt="..."
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    function renderSecondaryPictures() {
        console.log(firstBig);
        if ((firstBig && pictures.length > 1) || (!firstBig && pictures.length > 0)) {
            return (
                <div>
                    <div className="row row-cols-3">
                        {pictures.map((pic, i) => {
                            if (firstBig && i === 0) return null;
                            return (
                                <div className="col mb-4" key={i}>
                                    <span className="helper"></span>
                                    <div className="inlineblock">
                                        <img
                                            index={i}
                                            className="secondary-picture"
                                            onClick={displayPictureData}
                                            src={pic.uri}
                                            alt="..."
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="end-helper"></div>
                </div>
            );
        }
    }

    function renderPictureModal() {
        return (
            <div
                className="modal fade"
                id="picmodal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Détails de l'image
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ml-2 mr-2">
                            <div className="mt-3 mb-3 ">
                                {item.pictureUri ? (
                                    <img
                                        className="secondary-picture"
                                        src={item.pictureUri}
                                        alt="..."
                                    />
                                ) : (
                                    false
                                )}
                            </div>

                            <p>
                                <strong>Légende :</strong>{" "}
                                {item.legend ? (
                                    item.legend
                                ) : (
                                    <span className="text-secondary">non renseignée</span>
                                )}
                            </p>
                            <p>
                                <strong>Crédit photo ou copyright :</strong>{" "}
                                {item.copyright ? (
                                    item.copyright
                                ) : (
                                    <span className="text-secondary">non renseigné</span>
                                )}{" "}
                            </p>
                            <p>
                                <strong>Url image :</strong>{" "}
                                {item.pictureUri ? (
                                    <span className="small text-primary">{item.pictureUri}</span>
                                ) : (
                                    <span className="text-secondary">non renseignée</span>
                                )}
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal">
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (pictures && pictures.length > 0) {
        return (
            <div className="mosaic">
                {renderFirstBigPicture()}
                {renderSecondaryPictures()}
                {renderPictureModal()}
            </div>
        );
    } else {
        return <p>Vous n'avez pas encore importé d'image.</p>;
    }
};

export default PictureMosaic;
