import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import renderField from "./renderField";

const renderError = ({ meta: { touched, error } }) =>
    touched && error ? <span>{error}</span> : false;

const PlaceFormPage2 = (props) => {
    const { handleSubmit, previousPage, initialValues, loading, editMode, exitCallback } = props;

    useEffect(() => {
        // INIT VALUE HERE WITH THE ONES LOADED
        //props.dispatch(change("wizard", "tel", "phone number"));
        // TODO : ATTENTION on doit mettre un flag dans le state global pour eviter de recharger les données
        // tant qu'on a pas fait un submit ou une sauvegarde !!!! sinon les données seront écrasées si on
        // revient en arriere dans le wizard
        if (initialValues) {
            props.initialize(initialValues);
        }
    }, [initialValues]);

    return (
        <div className="row justify-content-md-center">
            <h4 className="center mb-4">Fiche descriptive : l'accroche</h4>

            <p className="col-md-11 text-primary">
                La fiche descriptive de votre site touristique est en quelque sorte votre carte de
                visite. Elle sera intégrée à chaque invitation. Elle doit être composée d'un
                descriptif très court qui sera l'accroche de votre site et d'une image afin de
                donner envie au destinataire de visiter votre site. 400 caractères maximum.
            </p>
            <form className="col-md-11" onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="sumup">
                        Accroche de votre site
                    </label>
                    <div>
                        <Field
                            cols="50"
                            rows="4"
                            maxLength="400"
                            className="form-control text-secondary"
                            name="sumup"
                            component="textarea"
                        />
                    </div>
                </div>

                <div>
                    <button type="button" className="btn btn-secondary mr-3" onClick={previousPage}>
                        Précédent
                    </button>
                    <button type="submit" className="btn btn-primary">
                        <span className={loading} role="status" aria-hidden="true"></span>
                        Sauvegarder et continuer
                    </button>

                    {editMode && (
                        <button
                            type="button"
                            onClick={exitCallback}
                            className="btn btn-link float-right">
                            Quitter l'édition
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default reduxForm({
    form: "wizard", //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(PlaceFormPage2);

/*


                <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="description">
                        Descriptif de votre site{" "}
                        <span>
                            (optionel, sera affiché si le destinataire souhaite en savoir plus)
                        </span>
                    </label>
                    <div>
                        <Field
                            cols="50"
                            rows="8"
                            className="form-control text-secondary"
                            name="description"
                            component="textarea"
                        />
                        <Field name="error" component={renderError} />
                    </div>
                </div>


                */
