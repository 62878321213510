import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { subscribe, loadOffers, resiliate, cancelAutoRenew } from "../actions/index";
import Offer from "./Offer";
import AlertConfirm from "./AlertConfirm";
import Subscription from "./Subscription";
import Errors from "../components/Errors";

function isActiveSubscription(subscriptions) {
    for (let i = 0; i < subscriptions.length; i++) {
        if (subscriptions[i].status === "active") {
            return true;
        }
        if (subscriptions[i].status === "resiliated") {
            console.log("resiliated");
            const currentTime = new Date().getTime(); // get epoch time
            if (
                new Date(subscriptions[i].startDate).getTime() < currentTime &&
                currentTime < new Date(subscriptions[i].endDate).getTime()
            ) {
                return true;
            }
        }
    }
    return false;
}

const PlaceSubscription = (props) => {
    const [selectedOffer, setOffer] = useState(null);
    // const [offers, initOffers] = useState([]);

    useEffect(() => {
        // LOAD OFFERS
        props.loadOffers();
    }, []);

    function onSubscribe(offer) {
        console.log("subscribe to offer : ", offer);
        setOffer(offer);
        // DISPLAY confirmation popup
        window.$("#confirmationPopup").modal("show");
    }

    function onRenew(activeSubscription) {
        console.log("Renew current subscription");
        props.subscribe(activeSubscription.offerId, props.place.placeId);
    }

    function onResiliate(activeSubscription) {
        console.log("Resiliate current subscription");
        props.resiliate(props.place.placeId);
    }

    function onCancelAutoRenew(activeSubscription) {
        console.log("Cancel autorenew of current subscription");
        props.cancelAutoRenew(props.place.placeId);
    }

    function renderSubscriptionStatus() {
        if (props.place.subscriptions && !isActiveSubscription(props.place.subscriptions)) {
            return (
                <div className="container">
                    <p style={{ fontSize: "1.3em" }} className="center mt-5 mb-4 ">
                        Vous n'avez pas encore souscrit à un abonnement Starplace, choisissez votre
                        formule :{" "}
                    </p>
                </div>
            );
        } else {
            return (
                <div className="container">
                    <p style={{ fontSize: "1.3em" }} className="center  mt-5 mb-4 ">
                        Vous avez souscrit à l'offre suivante :{" "}
                    </p>
                    <div className="center">
                        <Subscription
                            subscriptions={props.place.subscriptions}
                            offers={props.offers}
                            onSubscribe={onSubscribe}
                            onRenew={onRenew}
                            onResiliate={onResiliate}
                            onCancelAutoRenew={onCancelAutoRenew}
                        />
                    </div>
                </div>
            );
        }
    }

    function renderOfferSelection() {
        if (!isActiveSubscription(props.place.subscriptions)) {
            return (
                <div className="container">
                    <div className="d-flex justify-content-around flex-wrap mb-3 text-center">
                        {props.offers.map((offer, i) => {
                            return <Offer key={i} onSubscribe={onSubscribe} offer={offer} />;
                        })}
                    </div>
                </div>
            );
        }
    }

    function validatePurchase() {
        if (selectedOffer) {
            console.log("PURCHASE");
            // call action builder
            props.subscribe(selectedOffer.offerId, props.place.placeId);
        }

        window.$("#confirmationPopup").modal("hide");
    }

    if (!props.place) {
        return (
            <div className="container mt-5 mb-5">
                <p style={{ fontSize: "1.3em" }} className="center">
                    Aucune Starplace séléctionnée
                </p>
            </div>
        );
    }

    function renderSubscriptionConfirmationOrPayment() {
        return (
            <AlertConfirm
                id="confirmationPopup"
                title="Confirmez votre abonnement"
                cancelStr="Annuler"
                validateStr="Confirmer"
                validateCallback={validatePurchase}>
                {selectedOffer && (
                    <p>
                        Confirmez votre souscription à l'abonnement suivant :{" "}
                        <strong>
                            {selectedOffer.title} ({selectedOffer.price} {selectedOffer.currency})
                        </strong>
                    </p>
                )}
            </AlertConfirm>
        );
    }

    return (
        <div>
            <Errors />
            {renderSubscriptionStatus()}
            {renderOfferSelection()}
            {renderSubscriptionConfirmationOrPayment()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return { place: state.place.current, offers: state.offers };
};

const mapDispatchToProps = {
    subscribe,
    loadOffers,
    resiliate,
    cancelAutoRenew,
};
export default connect(mapStateToProps, mapDispatchToProps)(PlaceSubscription);
