import {
    SET_AUTHENTIFICATION,
    PARSE_ERROR,
    SET_CUSTOMER,
    SET_PLACE_LIST,
    SET_CURRENT_PLACE,
    RESET_ALL_PLACES,
    SET_OFFERS,
    RESET_ERROR,
    RESET_CURRENT_PLACE,
    SET_YEAR_STATS,
    SET_TODAY_STATS,
    RESET_CUSTOMER,
} from "./action-types";
import axios from "axios";
import CONSTANTS from "../constants";
import * as Tools from "../misc/tools";

const BASE_URL = CONSTANTS.BASE_URL;

let currentCustomerId = null;

function ifTimeout(dispatch, error) {
    if (error?.code === "ECONNABORTED") {
        dispatch(displayError("Le serveur semble injoignable, veuillez réessayer plus tard"));
        return true;
    } else if (!error.response) {
        dispatch(
            displayError("Impossible de se connecter au serveur, veuillez réessayer plus tard")
        );
        return true;
    }
    return false;
}

function handleError(dispatch, error, silenceForCode) {
    console.log("ERROR : ", error);
    if (ifTimeout(dispatch, error)) return;

    if (error.response && silenceForCode && error.response.status === silenceForCode) {
        return;
    }

    if (error.response && error.response.status === 401) {
        //dispatch(displayError("Identifiants invalides"));
        dispatch(signoutUser());
    } else if (error.response && error.response.status === 403) {
        dispatch(displayError("Cette opération n'est pas autorisée par le serveur"));
    } else if (error?.response?.status === 400) {
        if (error?.response?.data?.error === "ORGANIZATION_ALREADY_EXISTS") {
            dispatch(displayError("Un client avec la même raison sociale existe déjà"));
        } else if (error?.response?.data?.error === "EMAIL_ALREADY_EXISTS") {
            dispatch(displayError("L'email de contact est déjà prise par un autre client"));
        } else if (error?.response?.data?.error === "CUSTOMER_ALREADY_EXISTS") {
            dispatch(displayError("Il semble qu'un client identique existe déjà"));
        }
    } else if (error.response && error.response.status === 404) {
        if (error?.response?.data?.error === "ERROR_UNKNOWN_EMAIL") {
            dispatch(displayError("Email inconnu"));
        } else {
            dispatch(displayError("Le serveur a rencontré un problème : ressource inéxistante"));
        }
    } else {
        dispatch(displayError("Le serveur a rencontré un problème, veuillez réessayer plus tard"));
    }
}

export function setAuthentification(isLoggedIn) {
    return function (dispatch) {
        dispatch({ type: SET_AUTHENTIFICATION, payload: isLoggedIn });
    };
}

export function signoutUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("placeid");
    return function (dispatch) {
        dispatch({ type: RESET_CUSTOMER, payload: null });
        dispatch({ type: RESET_ALL_PLACES, payload: null });
        dispatch({ type: SET_AUTHENTIFICATION, payload: false });
    };
}

export function signinWithToken(customerId, token) {
    return function (dispatch) {
        localStorage.setItem(
            "token",
            JSON.stringify({
                customerId: customerId,
                token: token,
            })
        );

        dispatch(loadCurrentCustomer(customerId));
        dispatch(setAuthentification(true));
    };
}

export function signinUser({ email, password }, successCallback, errorCallback) {
    return function (dispatch) {
        const url = `${BASE_URL}/customer/login`;
        console.log("URL TO CONNECT : ", url);
        axios
            .post(
                url,
                {
                    email,
                    password,
                },
                { timeout: CONSTANTS.NETWORK_TIMOUT_MS }
            )
            .then((response) => {
                console.log(JSON.stringify(response));

                // save token (authentication cookie)
                localStorage.setItem(
                    "token",
                    JSON.stringify({
                        customerId: response.data.customerId,
                        token: response.data.token,
                    })
                );
                localStorage.removeItem("placeid");

                if (response.data.customerId) {
                    dispatch(setAuthentification(true));
                    dispatch(loadCurrentCustomer(response.data.customerId));
                }
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
                if (ifTimeout(dispatch, error)) return;
                if (error.response && error.response.status === 401) {
                    dispatch(displayError("Identifiants invalides"));
                } else {
                    dispatch(
                        displayError(
                            "Le serveur a rencontré un problème, veuillez réessayer plus tard"
                        )
                    );
                }
            });
    };
}

export function signupUser(data, successCallback, errorCallback) {
    return function (dispatch) {
        const url = `${BASE_URL}/customer/`;
        console.log("signupUser : ", url);
        axios
            .post(
                url,
                {
                    ...data,
                },
                { timeout: CONSTANTS.NETWORK_TIMOUT_MS }
            )
            .then((response) => {
                console.log("Temporary customer created waiting for verification");
                if (successCallback) successCallback();
            })
            .catch((error) => {
                handleError(dispatch, error);
                if (errorCallback) errorCallback();
            });
    };
}

export function displayError(errorMessage, duration) {
    return function (dispatch) {
        let timeDuration = CONSTANTS.MESSAGE_DURATION_IN_MILLIS;
        if (duration > 0) {
            timeDuration = duration;
        } else if (duration === 0) {
            timeDuration = 0;
        }
        dispatch({
            type: PARSE_ERROR,
            payload: { message: errorMessage, duration: timeDuration },
        });
    };
}

export function resetError(errorMessage) {
    return function (dispatch) {
        dispatch({ type: RESET_ERROR, payload: null });
    };
}

export function loadCurrentCustomer(customerId, successCallback, errorCallback) {
    currentCustomerId = customerId;
    return function (dispatch) {
        const url = `${BASE_URL}/customer/${customerId}`;
        console.log("URL TO CONNECT : ", url);
        axios
            .get(url, { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS })
            .then((response) => {
                console.log(JSON.stringify(response.data));
                dispatch({ type: SET_CUSTOMER, payload: response.data });
                dispatch(getPlaceList(customerId));
                const placeid = localStorage.getItem("placeid");
                if (placeid) {
                    dispatch(loadPlace(placeid));
                }

                if (successCallback) successCallback();
                //history.push("/places");
            })
            .catch((error) => {
                //handleError(dispatch, error);
                if (errorCallback) errorCallback(error);
            });
    };
}

export function updateCustomer(updatedCustomer, successCallback, errorCallback) {
    return function (dispatch) {
        const url = `${BASE_URL}/customer/${updatedCustomer.customerId}`;
        console.log("call updateCustomer : ", url);
        axios
            .put(url, updatedCustomer, {
                ...Tools.getAxiosOptionsAuth(),
                timeout: CONSTANTS.NETWORK_TIMOUT_MS,
            })
            .then((response) => {
                if (response.data) {
                    dispatch({ type: SET_CUSTOMER, payload: response.data });
                }
                if (successCallback) successCallback();
            })
            .catch((error) => {
                handleError(dispatch, error);
                if (errorCallback) errorCallback(error);
            });
    };
}

export function askPasswordReset(email, successResetCallback, errorCallback) {
    return function (dispatch) {
        const url = CONSTANTS.BASE_URL + "/customer/password/askreset";
        console.log("askPasswordReset : ", email, url);
        axios
            .post(url, { email: email }, { timeout: CONSTANTS.NETWORK_TIMOUT_MS })
            .then((response) => {
                if (response.data && successResetCallback) successResetCallback();
            })
            .catch((error) => {
                handleError(dispatch, error);
                if (errorCallback) errorCallback(error);
            });
    };
}

export function changePassword(values, successCallback, errorCallback) {
    return function (dispatch) {
        const url = `${BASE_URL}/customer/password/change`;
        console.log("call changePassword : ", url);
        axios
            .post(url, values, {
                ...Tools.getAxiosOptionsAuth(),
                timeout: CONSTANTS.NETWORK_TIMOUT_MS,
            })
            .then((response) => {
                if (response.data) {
                    dispatch({ type: SET_CUSTOMER, payload: response.data });
                }
                if (successCallback) successCallback();
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.status === 401) {
                    dispatch(displayError("Mot de passe erroné"));
                } else {
                    dispatch(
                        displayError(
                            "Le serveur a rencontré un problème, veuillez réessayer plus tard"
                        )
                    );
                }
                if (errorCallback) errorCallback();
            });
    };
}

export function getPlaceList(customerId) {
    return function (dispatch) {
        const url = `${BASE_URL}/place/list/${customerId}`;
        console.log("URL TO CONNECT : ", url);
        axios
            .get(url, { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS })
            .then((response) => {
                console.log("Mes places: ", JSON.stringify(response.data));
                dispatch({ type: SET_PLACE_LIST, payload: response.data });
            })
            .catch((error) => handleError(dispatch, error));
    };
}

export function loadOffers() {
    return function (dispatch) {
        const url = `${BASE_URL}/offers`;
        console.log("URL TO CONNECT : ", url);
        axios
            .get(url, { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS })
            .then((response) => {
                dispatch({ type: SET_OFFERS, payload: response.data });
            })
            .catch((error) => handleError(dispatch, error));
    };
}

export function resetCurrentPlace() {
    return function (dispatch) {
        dispatch({ type: RESET_CURRENT_PLACE, payload: null });
    };
}

export function loadPlace(placeId, history, displayStats) {
    return function (dispatch) {
        const url = `${BASE_URL}/place/${placeId}`;
        console.log("loadPlace  : ", placeId, url);
        axios
            .get(url, { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS })
            .then((response) => {
                // save current place id in cookies in case of reload
                localStorage.setItem("placeid", placeId);
                //console.log("LOAD PLACE : ", response.data);
                dispatch({ type: SET_CURRENT_PLACE, payload: response.data });
                dispatch(loadStats(placeId));
                if (history) {
                    if (displayStats) {
                        history.push("/places/stats");
                    } else {
                        history.push("/places/view");
                    }
                }
            })
            .catch((error) => handleError(dispatch, error));
    };
}

export function createOrUpdatePlace(place, callback) {
    console.log("Place : ", place);

    if (place.placeId) {
        //update
        const url = BASE_URL + "/place/" + place.placeId;
        return function (dispatch) {
            console.log("ACTION update place > url : ", url);
            axios
                .put(url, place, {
                    ...Tools.getAxiosOptionsAuth(),
                    timeout: CONSTANTS.NETWORK_TIMOUT_MS,
                })
                .then((response) => {
                    console.log(JSON.stringify(response));
                    callback(place.placeId);
                })
                .catch((error) => handleError(dispatch, error));
        };
    } else {
        // creation
        const url = BASE_URL + "/place";
        return function (dispatch) {
            console.log("ACTION create place > url : ", url);
            axios
                .post(url, place, {
                    ...Tools.getAxiosOptionsAuth(),
                    timeout: CONSTANTS.NETWORK_TIMOUT_MS,
                })
                .then((response) => {
                    console.log(JSON.stringify(response));
                    callback(response.data.place.placeId);
                })
                .catch((error) => handleError(dispatch, error));
        };
    }
}

export function deletePlace(placeId) {
    console.log("currentCustomerId : ", currentCustomerId);
    if (!(placeId && currentCustomerId)) return;
    const url = BASE_URL + "/place/" + placeId;
    return function (dispatch) {
        console.log("ACTION delete place : %s, for customer %s", placeId, currentCustomerId);
        axios
            .delete(url, { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS })
            .then((response) => {
                console.log(JSON.stringify(response));
                dispatch(getPlaceList(currentCustomerId));
            })
            .catch((error) => handleError(dispatch, error));
    };
}

export function subscribe(offerId, placeId) {
    const url = BASE_URL + "/subscription/";
    return function (dispatch) {
        console.log("ACTION create subscription > url : ", url);
        axios
            .post(
                url,
                { placeId, offerId },
                { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS }
            )
            .then((response) => {
                console.log(JSON.stringify(response));
                dispatch(loadPlace(placeId));
            })
            .catch((error) => handleError(dispatch, error));
    };
}

export function resiliate(placeId, silentCode) {
    const url = BASE_URL + "/subscription/resiliate/" + placeId;
    return function (dispatch) {
        console.log("ACTION resiliate subscription > url : ", url);
        axios
            .put(url, {}, { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS })
            .then((response) => {
                console.log(JSON.stringify(response));
                dispatch(loadPlace(placeId));
            })
            .catch((error) => handleError(dispatch, error, silentCode));
    };
}

export function cancelAutoRenew(placeId) {
    const url = BASE_URL + "/subscription/setautorenew/" + placeId;
    return function (dispatch) {
        console.log("ACTION cancel autorenew > url : ", url);
        axios
            .put(
                url,
                { autoRenew: false },
                { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS }
            )
            .then((response) => {
                console.log(JSON.stringify(response));
                dispatch(loadPlace(placeId));
            })
            .catch((error) => handleError(dispatch, error));
    };
}

export function loadStats(placeId, refreshToday) {
    const startDate = new Date();
    const endDate = new Date();

    if (!refreshToday) {
        // get 1 year of data + today data
        startDate.setDate(startDate.getDate() - 366);
        endDate.setDate(endDate.getDate());
    }

    const url =
        BASE_URL +
        "/place/stats/" +
        placeId +
        "?start=" +
        startDate.getTime() +
        "&end=" +
        endDate.getTime();
    return function (dispatch) {
        console.log("ACTION load stats > url : ", url);
        axios
            .get(url, { ...Tools.getAxiosOptionsAuth(), timeout: CONSTANTS.NETWORK_TIMOUT_MS })
            .then((response) => {
                if (refreshToday) {
                    console.log(JSON.stringify(response));
                    dispatch({ type: SET_TODAY_STATS, payload: response.data });
                } else {
                    console.log(JSON.stringify(response.data));
                    dispatch({ type: SET_YEAR_STATS, payload: response.data });
                }
            })
            .catch((error) => handleError(dispatch, error));
    };
}
