import React, { useState } from "react";
import { connect } from "react-redux";
import PictureMosaic from "./PictureMosaic";
import MapDisplay from "../containers/MapDisplay";
import AlertConfirm from "./AlertConfirm";
import { createOrUpdatePlace, loadPlace } from "../actions";
import "../css/PlaceView.css";

/*// POLICY : remove-after-watched , wait-expiration
const DEFAULT_RECORD_POLICY = "remove-after-watched";
// MAX RESOLUTION : 480, 720, 1080
const DEFAULT_RECORD_MAX_RESOLUTION = 1080;
// MAX SIZE in MO
const DEFAULT_RECORD_MAX_SIZE = 160;
// MAX DURATION in seconds
const DEFAULT_RECORD_MAX_DURATION = 30;
// RETENTION in month
const DEFAULT_RECORD_RETENTION = 12;
const DEFAULT_NB_RECORD_PER_USER_PER_DAY = 3;
const DEFAULT_NB_DOWNLOAD_PER_USER_PER_DAY_PER_RECORD = 1;*/

const PlaceView = ({ place, createOrUpdatePlace, loadPlace }) => {
    const [alertTitle, setAlertTitle] = useState("Default title");
    const [alertContent, setAlertContent] = useState(<p>default content</p>);
    const [alertValidate, setAlertValidate] = useState(null);

    function getWebUrl(url) {
        if (url.startsWith("http")) {
            return url;
        } else {
            return "http://" + url;
        }
    }

    function translateRecordPolicy(policy) {
        if (policy === "remove-after-watched") {
            return "suppression";
        }

        if (policy === "wait-expiration") {
            return "attendre l'expiration";
        }

        return "donnée invalide";
    }

    function suspendProduction() {
        setAlertTitle("Suspendre la production");
        setAlertContent(
            <div>
                <p>
                    Voulez-vous suspendre la production ? Cela entraînera l'impossibilité pour vos
                    visiteurs d'enregistrer ou de lire des vidéos sur votre site.
                </p>
                <p>
                    La suspension est conseillée pendant les mises à jour majeures de la description
                    de votre site.
                </p>
            </div>
        );
        setAlertValidate(() => {
            return function () {
                place.productionFlag = false;
                createOrUpdatePlace(place, () => {
                    console.log("Production suspended");
                    loadPlace(place.placeId, null);
                    window.$("#productionAlert").modal("hide");
                    jumpto("");
                });
            };
        });
        window.$("#productionAlert").modal("show");
    }

    function jumpto(anchor) {
        window.location.href = "#" + anchor;
        return false;
    }

    function hasActiveSubscription(subscriptions) {
        for (let i = 0; i < subscriptions.length; i++) {
            if (subscriptions[i].status === "active") {
                return true;
            }
            if (subscriptions[i].status === "resiliated") {
                console.log("resiliated");
                const currentTime = new Date().getTime(); // get epoch time
                if (
                    new Date(subscriptions[i].startDate).getTime() < currentTime &&
                    currentTime < new Date(subscriptions[i].endDate).getTime()
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    function checkProductionRequirement() {
        let tocheck = [];

        if (!(place.name && place.name.length > 0)) {
            tocheck.push("Vous devez définir le nom de votre lieu Starplace.");
        }

        if (!(place.sumup && place.sumup.length > 0)) {
            tocheck.push(
                "Vous devez fournir une accroche pour la fiche desriptive de votre lieu Starplace."
            );
        }

        if (!(place.address && place.address.length > 0)) {
            tocheck.push("Vous devez fournir une adresse pour votre lieu Starplace.");
        }

        if (
            !(
                place.subscriptions &&
                place.subscriptions.length > 0 &&
                hasActiveSubscription(place.subscriptions)
            )
        ) {
            tocheck.push("Vous devez souscrire à un abonnement (menu abonnement).");
        }

        if (!(place.thumbnail && place.thumbnail.length > 0)) {
            tocheck.push(
                "Vous devez fournir au moins une image pour la fiche descriptive de votre site."
            );
        }

        if (!(place.boundaries && place.boundaries.length > 0)) {
            tocheck.push(
                "Vous devez définir une zone géographique pour votre lieu Starplace (utilisez l'assistant d'édition pour définir la zone)."
            );
        }

        if (!(place.gps && place.gps.length > 0)) {
            tocheck.push(
                "Vous devez définir le point GPS de votre lieu Starplace (utilisez l'assistant d'édition pour pointer votre lieu)."
            );
        }

        return tocheck;
    }
    function activateProduction() {
        const tochecked = checkProductionRequirement();
        if (tochecked.length > 0) {
            // some checked shall be done before going in production
            // display alert message
            setAlertTitle("Actions nécessaires avant la mise en production");
            setAlertContent(
                <div>
                    <p>
                        Afin de mettre votre lieu Starplace en production merci de renseigner les
                        informations manquantes via le menu édition :{" "}
                    </p>
                    {tochecked.map((item, i) => {
                        return (
                            <p className="text-danger" key={i}>
                                - {item}
                            </p>
                        );
                    })}
                </div>
            );
            setAlertValidate(() => {
                return null;
            });
            window.$("#productionAlert").modal("show");
        } else {
            // no change required, go to production
            setAlertTitle("Activer la production");
            setAlertContent(
                <div>
                    <p>
                        Voulez vous mettre votre site en production ? Il sera visible de vos
                        visiteurs qui pourront enregistrer et lire des vidéos.
                    </p>
                </div>
            );
            setAlertValidate(() => {
                return function () {
                    place.productionFlag = true;
                    createOrUpdatePlace(place, () => {
                        console.log("Production activated");
                        loadPlace(place.placeId, null);
                        window.$("#productionAlert").modal("hide");
                        jumpto("");
                    });
                };
            });
            window.$("#productionAlert").modal("show");
        }
    }

    if (place) {
        return (
            <div>
                <div id="headerTitle" className="center mt-5 mb-4">
                    <h3>
                        {place.name}
                        {place.productionFlag && (
                            <span className="text-success ml-3"> [en production]</span>
                        )}
                    </h3>
                </div>
                <div className="row">
                    <div className="col-lg p-2">
                        <div id="description" className="card mb-3 px-3">
                            <div className="section-title">Descriptif</div>
                            <div className="field-title">Adresse</div>
                            <p>
                                {place.address}, {place.country}
                            </p>
                            <div className="field-title">Description</div>
                            <p>{place.sumup}</p>
                            <div className="field-title">Contact</div>
                            <p className="p">
                                <span style={{ display: "block" }}>
                                    <span className="text-black-50">Site internet :</span>{" "}
                                    <a href={getWebUrl(place.web)} target="_blank" rel="noreferrer">
                                        {getWebUrl(place.web)}
                                    </a>
                                </span>
                                <span style={{ display: "block" }}>
                                    <span className="text-black-50"> Téléphone :</span>{" "}
                                    {place.phone}
                                </span>
                            </p>
                        </div>
                        <div id="videoSettings" className="card mb-3 px-3">
                            <div className="section-title">Paramètres des vidéos</div>
                            <div className="conf-table mb-3">
                                <table className="table table-hover table-sm  ">
                                    <thead>
                                        <tr>
                                            <th scope="col">Paramètres</th>
                                            <th scope="col">Valeurs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="advanced-conf">
                                            <th scope="row">Durée de rétention (en mois)</th>
                                            <td>{place.retentionDuration}</td>
                                        </tr>
                                        <tr className="advanced-conf">
                                            <th scope="row">Durée maximale (en secondes)</th>
                                            <td>{place.recordMaxDuration}</td>
                                        </tr>
                                        <tr className="advanced-conf">
                                            <th scope="row">
                                                Nombre d'enregistrements / jour / personne
                                            </th>
                                            <td>{place.numberOfRecordPerUserPerDay}</td>
                                        </tr>
                                        <tr className="advanced-conf">
                                            <th scope="row">Résolution maximale</th>
                                            <td>{place.recordMaxResolution}</td>
                                        </tr>
                                        <tr className="advanced-conf">
                                            <th scope="row">Période de garde (en heure)</th>
                                            <td>{place.delayBeforeView}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg p-2">
                        <div id="pictures" className="card mb-3 px-3">
                            <div className="section-title">Images</div>
                            <PictureMosaic pictures={place.pictures} big={true} />
                        </div>
                        <div id="map" className="card mb-3 px-3" style={{ maxWidth: "600px" }}>
                            <div className="section-title">Votre lieu Starplace</div>
                            <div className="mb-3">
                                <MapDisplay
                                    boundaries={place.boundaries}
                                    gps={place.gps}
                                    zoom={place.zoom}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="actions" className="center m-4">
                    {place.productionFlag ? (
                        <button onClick={suspendProduction} className="btn btn-danger btn-lg">
                            Suspendre la production
                        </button>
                    ) : (
                        <button onClick={activateProduction} className="btn btn-primary btn-lg">
                            Passer en production
                        </button>
                    )}
                </div>

                <AlertConfirm
                    id="productionAlert"
                    title={alertTitle}
                    validateCallback={alertValidate}
                    cancelStr="Annuler"
                    validateStr="Confirmer">
                    {alertContent}
                </AlertConfirm>
            </div>
        );
    } else {
        return (
            <div className="container mt-5 mb-5">
                <p style={{ fontSize: "1.3em" }} className="center">
                    Aucune Starplace séléctionnée
                </p>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return { place: state.place.current };
};

const mapDispatchToProps = {
    createOrUpdatePlace,
    loadPlace,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceView);

/*

                                        <tr className="advanced-conf">
                                            <th scope="row">Taille maximale (en Mo)</th>
                                            <td>{place.recordMaxSize}</td>
                                        </tr>

                                        <tr className="advanced-conf">
                                            <th scope="row">
                                                Action après lecture par tous les invités
                                            </th>
                                            <td>{translateRecordPolicy(place.recordPolicy)}</td>
                                        </tr>

                                        <tr className="advanced-conf">
                                            <th scope="row">
                                                Nombre de téléchargements / personne / vidéo
                                            </th>
                                            <td>{place.numberOfDownloadPerUser}</td>
                                        </tr>

*/
