const REQUIRED_FIELD = "Ce champ est requis";

const INVALID_PHONE = "Numéro de téléphone non valide";
const INVALID_EMAIL = "Adresse email non valide";
const INVALID_POSTAL_CODE = "Code postal non valide";

function isValidPhone(str) {
    let pattern = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im); // fragment locator
    return !!pattern.test(str);
}

const validateCustomer = (values) => {
    const errors = {};

    ////// FORMULAIRE 1 : INFO GENERALES //////
    if (!values.organization) {
        errors.organization = REQUIRED_FIELD;
    }

    if (!values.addressLine1) {
        errors.addressLine1 = REQUIRED_FIELD;
    }

    if (!values.postalCode) {
        errors.postalCode = REQUIRED_FIELD;
    }

    if (!values.city) {
        errors.city = REQUIRED_FIELD;
    }

    if (!values.country) {
        errors.country = REQUIRED_FIELD;
    }

    if (!values.contactFirstName) {
        errors.contactFirstName = REQUIRED_FIELD;
    }

    if (!values.contactLastName) {
        errors.contactLastName = REQUIRED_FIELD;
    }

    if (!values.email) {
        errors.email = REQUIRED_FIELD;
    }

    if (!values.phone) {
        errors.phone = REQUIRED_FIELD;
    }

    if (!values.password) {
        errors.password = REQUIRED_FIELD;
    }

    if (!values.passwordcheck) {
        errors.passwordcheck = REQUIRED_FIELD;
    }

    if (values.passwordcheck && values.password && values.passwordcheck !== values.password) {
        errors.passwordcheck = "Les mots de passe sont différents";
    }

    if (values.password && values.password.length < 8) {
        errors.password = "Mot de passe trop court (il doit comporter 8 caractères)";
    }

    if (!values.newpassword) {
        errors.newpassword = REQUIRED_FIELD;
    }
    if (!values.newpasswordcheck) {
        errors.newpasswordcheck = REQUIRED_FIELD;
    }

    if (
        values.newpasswordcheck &&
        values.newpassword &&
        values.newpasswordcheck !== values.newpassword
    ) {
        errors.newpasswordcheck = "Les mots de passe sont différents";
    }

    if (values.newpassword && values.newpassword.length < 8) {
        errors.newpassword =
            "Mot de passe trop court (il doit comporter au moins 8 caractères, si possible avec des lettres minuscules, des majuscules, des chiffres et des caractères spéciaux)";
    }

    if (values.phone && !isValidPhone(values.phone)) {
        errors.phone = INVALID_PHONE;
    }

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = INVALID_EMAIL;
    }

    if (values.postalCode && !/^[A-Z0-9]+$/i.test(values.postalCode)) {
        errors.postalCode = INVALID_POSTAL_CODE;
    }

    return errors;
};

export default validateCustomer;
