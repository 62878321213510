const google = window.google;

export class ZMap {
    constructor(elem) {
        console.log("#### ZMAP CURRENTLY USES GOOGLE MAP IMPLEMENTATION ####");
        this.options = {
            //center:pcenter,
            //zoom:pzoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDoubleClickZoom: true,
            scaleControl: false,
            clickableIcons: false,
            panControl: true,
            zoomControl: true,
            mapTypeControl: true,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false,
        };

        this.map = new google.maps.Map(elem, this.options);
        this.map.setZoom(6);
        this.map.setCenter(new google.maps.LatLng(50.4167911, -5.7));
    }

    setOptions(options) {
        console.log("ZMAP setOptions");
        this.map.setOptions(options);
    }

    cleanAllMap() {}

    addListener(eventtype, fct) {
        //console.log("ZMAP addListener");
        return this.map.addListener(eventtype, fct);
    }

    removeListener(listener) {
        //console.log("ZMAP removeListener");
        return google.maps.event.removeListener(listener);
    }

    getNativeMap() {
        //console.log("ZMAP getNativeMap");
        return this.map;
    }

    setZoom(value) {
        console.log("ZMAP setZoom");
        this.map.setZoom(value);
    }

    getZoom(value) {
        console.log("ZMAP getZoom");
        return this.map.getZoom();
    }

    setCenter(point) {
        console.log("ZMAP setCenter");
        this.map.setCenter(point.getNativeLatLng());
    }

    fromLatLngToPoint(latlng) {
        //console.log("ZMAP fromLatLngToPoint");
        //console.log(latlng);
        var prj = this.map.getProjection();
        var p = prj.fromLatLngToPoint(latlng.getNativeLatLng());

        return p;
    }

    fromPointToLatLng(point) {
        //console.log("ZMAP fromPointToLatLng");
        var prj = this.map.getProjection();
        var nativeLatLng = prj.fromPointToLatLng(point);
        return new ZLatLng(nativeLatLng.lat(), nativeLatLng.lng());
    }

    refresh() {
        google.maps.event.trigger(this.map, "resize");
    }

    getEventLatLng(event) {
        //console.log(event);
        return new ZLatLng(event.latLng.lat(), event.latLng.lng());
    }

    getIconColor(type) {
        if (type) {
            if (type === "local") {
                return "http://maps.google.com/mapfiles/ms/icons/red.png";
            } else if (type === "area") {
                return "http://maps.google.com/mapfiles/ms/icons/green.png";
            } else if (type === "site") {
                return "http://maps.google.com/mapfiles/ms/icons/blue.png";
            }

            if (type === "processed") {
                return "http://maps.google.com/mapfiles/ms/icons/yellow.png";
            }
            if (type === "inprogress") {
                return "http://maps.google.com/mapfiles/ms/icons/pink.png";
            }
        }
        return "http://maps.google.com/mapfiles/ms/icons/red.png";
    }
}

export class ZPolygon {
    constructor(map, options) {
        this.poly = new google.maps.Polygon(options);
        this.vertices = new ZVertices(this, this.poly.getPath());
    }
    setDraggable(boolvalue) {
        this.poly.setDraggable(boolvalue);
    }

    setEditable(boolvalue) {
        this.poly.setEditable(boolvalue);
    }

    setVisible(boolvalue) {
        this.poly.setVisible(boolvalue);
    }

    getVisible() {
        return this.poly.getVisible();
    }

    getPath() {
        return this.vertices;
    }

    setPath(verticeArray) {
        this.poly.setPath(this.convertToNativeArray(verticeArray));
        this.vertices.setPath(verticeArray, this.poly.getPath());
    }

    setPaths(verticeArrayArray) {
        this.poly.setPaths(this.convertToNativeArray(verticeArrayArray));
        this.vertices.setPath(verticeArrayArray, this.poly.getPath());
    }

    convertToNativeArray(intab) {
        var tab = [];
        for (var i = 0; i < intab.length; i++) {
            //console.log(intab[i]);
            tab.push(new google.maps.LatLng(intab[i].lat(), intab[i].lng()));
        }
        return tab;
    }

    setOptions(options) {
        return this.poly.setOptions(options);
    }

    setMap(map) {
        if (map != null) {
            this.poly.setMap(map.getNativeMap());
        } else {
            this.poly.setMap(null);
        }
    }

    remove() {
        this.poly.setMap(null);
    }

    addListener(eventtype, fct) {
        return this.poly.addListener(eventtype, fct);
    }

    removeListener(listener) {
        return google.maps.event.removeListener(listener);
    }
}

export class ZVertices {
    constructor(poly, initdata) {
        this.data = this.convertNativeToZTab(initdata);
        this.nativetab = initdata;
    }

    convertNativeToZTab(intab) {
        var tab = [];

        for (var i = 0; i < intab.getLength(); i++) {
            var v = intab.getAt(i);
            tab.push(new ZLatLng(v.lat(), v.lng()));
        }
        return tab;
    }

    convertZTabToNative(intab) {
        var tab = [];

        for (var i = 0; i < intab.length; i++) {
            //console.log(intab[i]);
            tab.push(new google.maps.LatLng(intab[i].lat(), intab[i].lng()));
        }
        return tab;
    }

    push(item) {
        this.data.push(item);
        this.nativetab.push(new google.maps.LatLng(item.lat(), item.lng()));
    }
    getLength() {
        return this.data.length;
    }

    updateNativeVertices(nativedata) {
        this.nativetab = nativedata;
        this.convertNativeToZTab(this.nativetab);
    }

    update() {
        this.data = this.convertNativeToZTab(this.nativetab);
        return this;
    }

    getAt(index) {
        // console.log("getAt, index: " + index + ", lat: " + this.data[index].lat() + ", lng: " + this.data[index].lng());
        return this.data[index];
    }

    setAt(index, zlatlng) {
        console.log(
            "setAt, index: " + index + ", lat: " + zlatlng.lat() + ", lng: " + zlatlng.lng()
        );
        this.data[index] = zlatlng;
        this.nativetab.setAt(index, new google.maps.LatLng(zlatlng.lat(), zlatlng.lng()));
    }

    getNativeData() {
        return this.nativetab;
        //return this.convertZTabToNative(this.data);
    }

    getArray() {
        // return getNativeData().getArray();
        return this.data;
    }

    setPath(newdata, nativedata) {
        this.data = newdata;
        this.nativetab = nativedata;
    }
}

export class ZCircle {
    constructor(options) {
        var convertedOptions = {
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight,
            fillColor: options.fillColor,
            fillOpacity: options.fillOpacity,
            draggable: options.draggable,
            map: options.map.getNativeMap(),
            center: options.center.getNativeLatLng(),
            radius: options.radius,
        };
        this.circle = new google.maps.Circle(convertedOptions);
    }

    getCenter() {
        var center = this.circle.getCenter();
        console.log("getcenter: " + center.lat() + " - " + center.lng());
        return new ZLatLng(center.lat(), center.lng());
    }

    setMap(map) {
        if (map != null) {
            this.circle.setMap(map.getNativeMap());
        } else {
            this.circle.setMap(null);
        }
    }

    remove() {
        this.circle.setMap(null);
    }
}

export class ZLatLng {
    constructor(lat, lng) {
        this.latlng = new google.maps.LatLng(lat, lng);
    }

    getNativeLatLng() {
        return this.latlng;
    }

    lat() {
        return this.latlng.lat();
    }

    lng() {
        return this.latlng.lng();
    }
}

export class ZMarker {
    constructor(options) {
        this.nativeoptions = {
            position: options.position.getNativeLatLng(),
            map: options.map.getNativeMap(),
            label: options.label,
            icon: options.icon,
        };

        this.marker = new google.maps.Marker(this.nativeoptions);
    }

    getNativeMarker() {
        return this.marker;
    }

    remove() {
        this.marker.setMap(null);
    }

    setTitle(title) {
        this.marker.setTitle(title);
    }

    getTitle() {
        return this.marker.getTitle();
    }

    setLabel(label) {
        this.marker.setLabel(label);
    }

    setPosition(loc) {
        console.log("DEBUG set position");
        console.log(loc);
        this.marker.setPosition(loc.getNativeLatLng());
    }

    getPosition() {
        var np = this.marker.getPosition();
        return new ZLatLng(np.lat(), np.lng());
    }

    setAnimation(animationType) {
        var typeanim = null;
        if (animationType === "bounce") {
            typeanim = google.maps.Animation.BOUNCE;
        }
        this.marker.setAnimation(typeanim);
    }

    addListener(eventtype, fct) {
        return this.marker.addListener(eventtype, fct);
    }

    removeListener(listener) {
        return google.maps.event.removeListener(listener);
    }

    setVisible(boolvalue) {
        this.marker.setVisible(boolvalue);
    }
}

export class ZGeoCoder {
    constructor() {
        this.status = {
            OK: 1,
            ERROR: 0,
        };

        this.geocoder = new google.maps.Geocoder();
    }
    geocode(address, fct) {
        this.geocoder.geocode({ address: address }, fct);
    }

    getValueFromResult(results) {
        var loc = results[0].geometry.location;
        console.log("geocode fromresult: " + loc.lat() + " - " + loc.lng());
        return new ZLatLng(loc.lat(), loc.lng());
    }

    getValueFromStatus(status) {
        if (status === google.maps.GeocoderStatus.OK) {
            console.log("geocode getValueFromStatus OK");
            return this.status.OK;
        } else {
            console.log("geocode getValueFromStatus ERROR");
            return this.status.ERROR;
        }
    }

    getErrorMessage(status) {
        return status;
    }
}

export class ZGeoRoute {
    constructor(map) {
        this.status = {
            OK: 1,
            ERROR: 0,
        };

        this.map = map.getNativeMap();
        this.directionsService = new google.maps.DirectionsService();
        this.directionsDisplay = new google.maps.DirectionsRenderer();
        this.directionsDisplay.setMap(this.map);
        this.directionsDisplay.setOptions({ suppressMarkers: true });
    }

    route(waypoints, fct) {
        if (waypoints.length > 1) {
            var intermediates = [];
            if (waypoints.length > 2) {
                for (var i = 0; i < waypoints.length; i++) {
                    if (i !== 0 && i !== waypoints.length - 1) {
                        intermediates.push({ location: waypoints[i].getNativeLatLng() });
                    }
                }
            }

            this.directionsService.route(
                {
                    origin: waypoints[0].getNativeLatLng(),
                    destination: waypoints[waypoints.length - 1].getNativeLatLng(),
                    travelMode: google.maps.TravelMode.DRIVING,
                    waypoints: intermediates,
                },
                fct
            );
        }
    }

    draw(response) {
        this.directionsDisplay.setDirections(response);
    }

    getValueFromStatus(status) {
        if (status === google.maps.DirectionsStatus.OK) {
            console.log("georoute getValueFromStatus OK");
            return this.status.OK;
        } else {
            console.log("georoute getValueFromStatus ERROR");
            return this.status.ERROR;
        }
    }

    getLegsDistances(response) {
        var route = response.routes[0];
        var kmTab = [];
        for (var i = 0; i < route.legs.length; i++) {
            kmTab.push(Math.round(route.legs[i].distance.value / 1000));
        }
        return kmTab;
    }

    getErrorMessage(status) {
        return status;
    }
}
