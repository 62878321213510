import React from "react";
import "../css/AddCard.css";

const AddCard = ({ title, subtitle, actionText, actionUrl }) => {
    return (
        <div onClick={actionUrl} className="card card-place card-add">
            <div className="card-add-icon">
                <svg
                    width="3em"
                    height="3em"
                    className="bi bi-plus-circle center"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                        fillRule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                    />
                </svg>
            </div>
            <div className="card-body">
                <h5 className="card-title mt-2">{title}</h5>
                <p className="card-text mb-4">{subtitle}</p>
            </div>
        </div>
    );
};

export default AddCard;
/*

            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{subtitle}</p>
                <button onClick={actionUrl} className="btn btn-primary">
                    {actionText}
                </button>
            </div>

*/
