import React from "react";

const AlertConfirm = ({ id, children, title, cancelStr, validateStr, validateCallback, big }) => {
    let classModalStr = "modal-dialog";
    if (big && big === true) {
        classModalStr = "modal-dialog modal-xl";
    }

    return (
        <div
            className="modal fade"
            id={id}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className={classModalStr} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-primary" id="exampleModalLabel">
                            {title}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">{children}</div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">
                            {cancelStr}
                        </button>
                        {validateCallback && (
                            <button
                                type="button"
                                onClick={validateCallback}
                                className="btn btn-primary">
                                {validateStr}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertConfirm;
