import React, { Component } from "react";
import PictureMosaic from "../components/PictureMosaic";
import { displayError } from "../actions";
import { connect } from "react-redux";
import Errors from "../components/Errors";
import "./PictureUploader.css";
import CONSTANTS from "../constants";

class PictureUploader extends Component {
    _handleSubmit(e) {
        e.preventDefault();
    }

    _handleImageChange(e) {
        e.preventDefault();

        let file = e.target.files[0];
        if (!file) return;

        if (file.size > CONSTANTS.PICTURE_SELECTION_MAX_SIZE) {
            /*toast.error("Image trop volumineuse (taille maximale de 5 Méga octet)", {
                position: toast.POSITION.BOTTOM_CENTER,
            });*/
            this.props.displayError("Image trop volumineuse (taille maximale de 5 Méga octet)");
            return;
        }

        let reader = new FileReader();
        reader.onloadend = () => {
            console.log("update caller State using the loadedCallback");

            if (this.props.loadedCallback)
                this.props.loadedCallback({
                    file: file,
                    imagePreviewUrl: reader.result,
                });
        };

        reader.readAsDataURL(file);
    }

    render() {
        const { submitCallback, imagePreviewUrl } = this.props;
        window.$("#loadPictureInput").val("");

        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <PictureMosaic big={true} pictures={[{ uri: imagePreviewUrl }]} />;
        } else {
            $imagePreview = (
                <div className="mb-3">
                    Sélectionner un fichier afin d'importer l'image correspondante.
                </div>
            );
        }
        return (
            <>
                <Errors />
                <div className="picture-uploader">
                    <div className="form-inline" onSubmit={(e) => this._handleSubmit(e)}>
                        <div className="custom-file mb-3">
                            <label className="custom-file-label" htmlFor="loadPictureInput">
                                Choisir une image...
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                className="custom-file-input"
                                id="loadPictureInput"
                                onChange={(e) => this._handleImageChange(e)}
                            />

                            <div className="invalid-feedback"></div>
                        </div>
                        <div>{$imagePreview}</div>
                        {submitCallback && (
                            <button
                                className="submitButton btn btn-primary mb-2"
                                type="button"
                                onClick={(e) => this._handleSubmit(e)}>
                                Transférer l'image et sauvegarder
                            </button>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    displayError,
};

export default connect(null, mapDispatchToProps)(PictureUploader);
