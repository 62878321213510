import React, { Component } from "react";
import * as actions from "../actions";
import { connect } from "react-redux";
// import of withRouter to access the props.location
import { withRouter } from "react-router";

class Errors extends Component {
    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            this.props.resetError();
        }
    }

    timerStart = () => {
        if (this.props.duration === 0) {
            // 0 min infinite
            return;
        }
        //starts timer to clear display
        setTimeout(() => {
            //console.log("Expired");
            this.props.resetError();
        }, this.props.duration);
    };

    render() {
        return (
            this.props.error &&
            this.props.error.length > 0 && (
                <div className=" container alert alert-danger mt-3" role="alert">
                    {this.timerStart()}
                    {this.props.error}
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.errors.message,
        duration: state.errors.duration,
    };
};
export default withRouter(connect(mapStateToProps, actions)(Errors));
