import React, { Component } from "react";
import { connect } from "react-redux";
import Errors from "./Errors";
import { displayError } from "../actions";
import CONSTANTS from "../constants";
import axios from "axios";
import * as Tools from "../misc/tools";
import queryString from "query-string";
import { Dots } from "react-activity";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            password: null,
            processing: true,
        };
    }

    componentDidMount = () => {
        console.log("try to reset password");
        const parsed = queryString.parse(this.props.location.search);
        console.log("PARSED: ", parsed);

        this.setState({ processing: true }, () => {
            const url = CONSTANTS.BASE_URL + "/customer/password/resetpass?id=" + parsed.id;
            axios
                .get(url, { timeout: CONSTANTS.NETWORK_TIMOUT_MS })
                .then((response) => {
                    if (response?.data?.newPassword) {
                        this.successResetCallback(response.data.newPassword);
                    } else {
                        this.setState({
                            processing: false,
                            errorMessage: response?.data?.message,
                            password: null,
                        });
                    }
                })
                .catch((error) => {
                    console.log("Erreur serveur : ", error);
                    this.props.displayError(
                        "Une erreur serveur est survenue, veuillez réessayer plus tard.",
                        0
                    );
                });
        });
    };

    successResetCallback = async (newPassword) => {
        this.setState({
            processing: false,
            errorMessage: null,
            password: newPassword,
        });
    };

    getLoadingClassName = () => {
        if (this.state.processing) {
            return "spinner-border spinner-border-sm mr-2";
        } else {
            return "d-none";
        }
    };
    goToSignin = () => {
        this.props.history.push("/signin");
    };
    render() {
        const { password, processing, errorMessage } = this.state;
        return (
            <div className="flex-center">
                <div className="center mt-5 mb-3">
                    <img src="/banner-starplace.png" width="400" alt="" className="center" />
                </div>
                <div className="">
                    <div style={{ maxWidth: 500 }}>
                        <Errors />
                    </div>
                </div>
                {processing && <Dots />}
                {password && (
                    <div>
                        <div className="center mt-5">
                            Votre nouveau mot de passe est <strong>{password}</strong>
                        </div>
                        <div className="center mt-3">Pensez à le modifier une fois connecté.</div>
                    </div>
                )}
                {errorMessage && (
                    <div>
                        <div className="center mt-5">{errorMessage}</div>
                    </div>
                )}
                <div>
                    <button className="btn btn-primary mt-5 " onClick={this.goToSignin}>
                        Connexion
                    </button>
                </div>
            </div>
        );
    }
}

export default connect(null, { displayError })(ResetPassword);
