import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../components/not-found";
//import Home from "./home";
import Signin from "../components/Signin";
import Verify from "../components/Verify";
import CheckEmail from "../components/CheckEmail";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";

import PlacesWrap from "./PlacesWrap";
import Customer from "../components/Customer";
import SignOut from "../components/Signout";
import Signup from "../components/Signup";
import MainHeader from "../components/MainHeader";
import Errors from "../components/Errors";

import PictureUploader from "./PictureUploader";
import PlaceFormWizard from "./PlaceFormWizard";
import { connect } from "react-redux";
import { loadCurrentCustomer, setAuthentification } from "../actions";
import { Dots } from "react-activity";

require("../style.css");

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        // read the token component, if exists, then load customer and related data and at list
        const tokenString = localStorage.getItem("token");

        if (tokenString?.length > 0) {
            console.log("Token found during init, load data directly... : ", tokenString);
            const data = JSON.parse(tokenString);
            console.log("DATA: ", data.customerId, data.token);
            this.props.loadCurrentCustomer(
                data.customerId,
                () => {
                    this.props.setAuthentification(true);
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, 200);
                },
                () => {
                    localStorage.removeItem("token");
                    this.setState({ loading: false });
                }
            );
        } else {
            console.log("No token found during init so continue...");

            this.setState({ loading: false });
        }
    }

    render() {
        const { isAuthenticated } = this.props;
        const { loading } = this.state;

        return (
            <div>
                {!isAuthenticated && loading && (
                    <div className="fullHeightGrid">
                        <div className="placeSelfCenter">
                            <div className="center">
                                <div style={{ height: 200 }}>
                                    <Dots />
                                    <Errors />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isAuthenticated && (
                    <div>
                        <MainHeader />
                        <div className="container">
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/places" />
                                </Route>
                                <Route path="/places" component={PlacesWrap} />
                                <Route path="/verify" component={Verify} />
                                <Route path="/signout" component={SignOut} />
                                <Route path="/customer" component={Customer} />
                                <Route path="/picture" component={PictureUploader} />
                                <Route path="/wizard" component={PlaceFormWizard} />
                                <Route path="*" component={NotFound} />
                            </Switch>
                        </div>
                    </div>
                )}
                {!isAuthenticated && !loading && (
                    <div className="container">
                        <Switch>
                            <Route exact path="/" component={Signin} />
                            <Route path="/create" component={Signup} />
                            <Route path="/verify" component={Verify} />
                            <Route path="/email" component={CheckEmail} />
                            <Route path="/forgotpassword" component={ForgotPassword} />
                            <Route path="/resetpass" component={ResetPassword} />
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.customer.isAuthenticated,
    };
};

const mapDispatchToProps = { loadCurrentCustomer, setAuthentification };

export default connect(mapStateToProps, mapDispatchToProps)(App);
