import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import CustomerEdit from "./CustomerEdit";
import CustomerPassword from "./CustomerPassword";
import Errors from "./Errors";

class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            loading: false,
            passwordChange: false,
        };
    }

    componentDidMount() {}

    getLoadingClassName = () => {
        if (this.state.loading) {
            return "spinner-border spinner-border-sm mr-2";
        } else {
            return "d-none";
        }
    };

    startEdition = () => {
        this.setState({ edit: true });
    };

    stopEdition = () => {
        this.setState({ edit: false });
    };

    startPasswordChange = () => {
        this.setState({ passwordChange: true });
    };
    stopPasswordChange = () => {
        this.setState({ passwordChange: false });
    };

    render() {
        const { customer } = this.props;
        const { edit, passwordChange } = this.state;
        return (
            <div>
                <div className="mt-5 mb-5">
                    {!edit && !passwordChange && (
                        <div style={{ maxWidth: 550 }} className="margin-auto card  py-2 px-4">
                            <div className="section-title mb-4">Vos informations de compte</div>
                            <div className="field-title ">Raison sociale / Organisation</div>
                            <p>{customer.organization}</p>
                            <div className="field-title mt-3">Adresse</div>
                            <div>
                                <div>{customer.addressLine1}</div>
                                {customer.addressLine2?.length > 0 && (
                                    <div>{customer.addressLine2}</div>
                                )}
                                <div>
                                    {customer.postalCode} {customer.city}, {customer.country}
                                </div>
                            </div>
                            <div className="field-title mt-3">Contact administrateur</div>
                            <div>
                                <div>
                                    {customer.contactFirstName} {customer.contactLastName}
                                </div>
                                <div>Email : {customer.email}</div>
                                <div>Téléphone : {customer.phone}</div>
                            </div>
                            <div className="center mt-4">
                                <button onClick={this.startEdition} className="btn btn-link ">
                                    Modifier mes informations
                                </button>
                            </div>
                            <div className="center  mb-2">
                                <button
                                    onClick={this.startPasswordChange}
                                    className="btn btn-link ">
                                    Changer mon mot de passe
                                </button>
                            </div>
                        </div>
                    )}
                    {edit && <CustomerEdit stopEdition={this.stopEdition} />}
                    {passwordChange && (
                        <CustomerPassword stopPasswordChange={this.stopPasswordChange} />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        customer: state.customer.current,
    };
};
export default connect(mapStateToProps, actions)(Customer);
