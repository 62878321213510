import { PARSE_ERROR, RESET_ERROR } from "../actions/action-types";
import CONSTANTS from "../constants";

const initialState = {
    message: "",
    duration: CONSTANTS.MESSAGE_DURATION_IN_MILLIS,
};

export default function ErrorReducer(state = initialState, action) {
    switch (action.type) {
        case PARSE_ERROR:
            return {
                message: action.payload.message,
                duration: action.payload.duration,
            };
        case RESET_ERROR:
            return initialState;
        default:
            return state;
    }
}
