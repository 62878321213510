import React from "react";

const Offer = ({ offer, onSubscribe, suscribed }) => {
    function convertCurrency(currency) {
        if (currency === "EUR") {
            return "€";
        }
    }

    function renderPeriod(type, duration) {
        let periodStr = " non dispo";
        let valStr = "/ ";
        const res = duration.split(":");
        if (res.length > 1) {
            const val = parseInt(res[1]);
            let plurial = "";
            if (val > 1) {
                valStr = `/ ${val} `;
                plurial = "s";
            }

            const base = res[0];
            switch (base) {
                case "d":
                    periodStr = "jour" + plurial;
                    break;
                case "m":
                    periodStr = "mois";
                    break;
                case "y":
                    periodStr = "an" + plurial;
                    break;
                default:
                    break;
            }
        }
        return valStr + periodStr;
    }

    function renderSumupPart() {
        return offer.sumup.map((item, i) => {
            return <li key={i}>{item}</li>;
        });
    }

    function getBtnClassNames() {
        let btnappearance = "btn-primary";
        if (offer.type === "trial") {
            btnappearance = "btn-outline-primary";
        }
        return "btn btn-lg btn-block " + btnappearance;
    }

    function onClick() {
        if (onSubscribe) onSubscribe(offer);
    }

    return (
        <div style={{ width: "400px" }} className="card mb-5 shadow-sm">
            <div className="card-header">
                <h4 className="my-0 font-weight-normal">{offer.title}</h4>
            </div>
            <div className="card-body d-flex flex-column">
                <h1 className="card-title pricing-card-title">
                    {offer.price}
                    {convertCurrency(offer.currency)}{" "}
                    <small className="text-muted">{renderPeriod(offer.type, offer.duration)}</small>
                </h1>
                <div className="text-muted">(Prix hors taxe)</div>
                <div className="d-flex flex-column flex-grow-1">
                    <div className="d-block flex-grow-1">
                        <ul className="list-unstyled mt-3 mb-4">{renderSumupPart()}</ul>
                    </div>
                    <button onClick={onClick} type="button" className={getBtnClassNames()}>
                        {offer.type === "trial" ? "Inscription gratuite" : "Commencer"}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Offer;
