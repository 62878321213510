import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PlaceRoutes from "../containers/PlaceRoutes";
import Places from "../containers/Places";

class PlacesWrap extends Component {
    render() {
        return (
            <div>
                <div>
                    <Switch>
                        <Route exact path="/places/" component={Places} />
                        <Route path="/places/*" component={PlaceRoutes} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default PlacesWrap;
