import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class MainHeader extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg py-1 bg-light shadow-sm mb-3">
                <div className="container">
                    <a href="/" className="navbar-brand">
                        <img
                            src="/banner-starplace.png"
                            width="300"
                            alt=""
                            className="d-inline-block align-middle mr-3"
                        />

                        <span className="text-uppercase font-weight-bold"></span>
                    </a>

                    <button
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        className="navbar-toggler navbar-light">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div id="navbarSupportedContent" className="collapse navbar-collapse">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <NavLink
                                    exact
                                    activeClassName="selected"
                                    className="nav-link"
                                    to="/places">
                                    Mes Starplaces
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="selected"
                                    className="nav-link"
                                    to="/customer">
                                    Mon compte
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="selected"
                                    className="nav-link"
                                    to="/signout">
                                    Déconnexion
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default MainHeader;
