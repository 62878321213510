import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

class Signout extends Component {
    componentWillMount() {
        this.props.signoutUser();
    }

    render() {
        return <div className="justify-content-md-center"> A Bientôt... </div>;
    }
}

export default connect(null, actions)(Signout);
