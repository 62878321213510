import React from "react";

const NotFound = () => {
    return (
        <div>
            <h1>Erreur 404</h1>
        </div>
    );
};

export default NotFound;
