import React from "react";
import "../css/gradients.css";
/*
<i
className="bi bi-cursor float-left"
style={{ fontSize: "1.5rem", color: "white" }}></i>
*/

const StatsItem = ({ title, value, decoration, color, textClassName }) => {
    function getClassName() {
        return "card " + decoration;
    }

    return (
        <div className={getClassName()}>
            <div className="card-content">
                <div style={{ padding: "10px 20px 10px 20px" }} className="card-body">
                    <div className="media d-flex">
                        <div className="align-self-center"></div>
                        <div
                            style={{ color: color }}
                            className={"media-body text-right " + textClassName}>
                            <h3>{value}</h3>
                            <span style={{ color: "black" }}>{title}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default StatsItem;
