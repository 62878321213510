import React, { Component } from "react";
import { connect } from "react-redux";
import { resetCurrentPlace, resiliate, deletePlace } from "../actions";
import PlaceList from "../components/PlaceList";
import FirstCreateCard from "../components/FirstCreateCard";
import AlertConfirm from "../components/AlertConfirm";
import Errors from "../components/Errors";

class Places extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPlaceId: null,
            currentTitle: "",
        };
    }

    getCustomerName = () => {
        if (this.props.customer) {
            return this.props.customer.organization;
        }
    };

    componentDidMount() {
        // reset all previous current Place
        this.props.resetCurrentPlace();
    }

    askDeletion = (placeId, title) => {
        this.setState({ currentPlaceId: placeId });
        this.setState({ currentTitle: title });
        //  console.log("ASK TO DELETE : ", this.state.currentTitle, this.state.currentPlaceId);
        window.$("#deletePlaceAlert").modal("show");
    };

    deleteStarplace = () => {
        console.log("TRY TO DELETE : ", this.state.currentTitle, this.state.currentPlaceId);
        this.props.resiliate(this.state.currentPlaceId, 404);
        window.$("#deletePlaceAlert").modal("hide");
        setTimeout(() => {
            this.props.deletePlace(this.state.currentPlaceId);
        }, 1000);
    };

    createNewPlace = () => {
        this.props.history.push("/wizard");
    };

    render() {
        const { placeList } = this.props;
        return (
            <div>
                <div className="mt-5">
                    <p className="mb-4 center">
                        Bonjour <strong>{this.getCustomerName()}</strong>, bienvenue sur votre
                        espace client Starplace.
                    </p>
                    <div className="flex-center">
                        <div style={{ maxWidth: 550 }} className="center">
                            <Errors />
                        </div>
                    </div>
                    {placeList && placeList.length > 0 ? (
                        <div className="container">
                            <h3 className="mb-5 center">Vos lieux Starplace</h3>

                            <PlaceList
                                history={this.props.history}
                                list={placeList}
                                deletionCallback={this.askDeletion}
                            />
                        </div>
                    ) : (
                        <div className="container flex-center mt-5">
                            <FirstCreateCard createPlace={this.createNewPlace} />
                        </div>
                    )}
                    <AlertConfirm
                        id="deletePlaceAlert"
                        title="Suppression de votre site Starplace"
                        validateCallback={this.deleteStarplace}
                        cancelStr="Annuler"
                        validateStr="Confirmer">
                        <div>
                            Attention ! Cette opération est irréversible : voulez-vous vraiment
                            supprimer votre lieu Starplace ({this.state.currentTitle}) et toutes les
                            ressources associées (vidéos, invitations...) ? La suppression
                            entraînera la résiliation de l'abonnement associé.
                        </div>
                    </AlertConfirm>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        customer: state.customer.current,
        placeList: state.place.list,
    };
};

const mapDispatchToProps = {
    resetCurrentPlace,
    resiliate,
    deletePlace,
};
export default connect(mapStateToProps, mapDispatchToProps)(Places);
