import React, { Component } from "react";
import Stepper from "../stepper";
import { connect } from "react-redux";
import WizardFormPage1 from "../components/PlaceFormPage1";
import WizardFormPage2 from "../components/PlaceFormPage2";
import WizardFormPage3 from "../components/PlaceFormPage3";
import WizardFormPage4 from "../components/PlaceFormPage4";
import WizardFormPage5 from "../components/PlaceFormPage5";
import { loadPlace, getPlaceList, createOrUpdatePlace } from "../actions";
import Errors from "../components/Errors";
import "../css/PlaceFormWizard.css";

class PlaceWizardForm extends Component {
    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.editMode = false;

        this.state = {
            page: 0,
            steps: [
                { title: "Info générale", onClick: () => this.onClick(0) },
                { title: "Description", onClick: () => this.onClick(1) },
                { title: "Images", onClick: () => this.onClick(2) },
                { title: "Carte", onClick: () => this.onClick(3) },
                { title: "Paramètres vidéo", onClick: () => this.onClick(4) },
            ],
            place: {},
            loading: false,
            syncFlag: false,
        };
    }

    componentDidMount() {
        if (this.props.customer) {
            // if a place is set as props then copy data in current place
            // place is outside of state because place update is not intended to update component
            if (this.props.currentPlace) {
                console.log("EDIT MODE DETECTED");
                this.editMode = true;
                this.copyFields(this.props.currentPlace);
            } else {
                if (!this.state.place.placeId) {
                    console.log("CREATION MODE DETECTED");
                    const place = {
                        customerId: this.props.customer.customerId,
                        country: this.props.customer.country,
                        lang: "fr",
                        recordPolicy: "wait-expiration",
                        recordMaxResolution: "480",
                        recordMaxSize: 100,
                        recordMaxDuration: 30,
                        retentionDuration: 12,
                        numberOfRecordPerUserPerDay: 3,
                        numberOfDownloadPerUser: 2,
                        delayBetweenRecordDay: 1,
                        delayBeforeView: 0,
                        /* name: "Chateau du Roy",
                        address: "10 rue des roses, Orleans",
                        sumup: "C'est de la balle",
                        description: "blabla bla vla ...",
                        classification: "parc-jardin",
                        phone: "0101020202",
                        web: "aaaa.fr",
                        country: this.props.customer.country,
                        lang: "fr",
                        recordPolicy: "wait-expiration",
                        recordMaxResolution: "1080",
                        recordMaxSize: 160,
                        recordMaxDuration: 30,
                        retentionDuration: 12,
                        numberOfRecordPerUserPerDay: 3,
                        numberOfDownloadPerUser: 1,*/
                    };
                    this.copyFields(place);
                }
            }
        }
    }

    // To do : deep copy?
    onClick = (index) => {
        if (this.editMode === true) {
            this.setState({ page: index });
        }
    };

    copyFields(inPlace, func) {
        this.setState({ place: inPlace }, func);
    }

    getLoadingClassName = () => {
        if (this.state.loading) {
            return "spinner-border spinner-border-sm mr-2";
        } else {
            return "d-none";
        }
    };

    saveData1(data) {
        this.copyFields(data, () => {
            this.props.createOrUpdatePlace(this.state.place, (placeId) => {
                console.log("process form page 1 with SUCCESS");
                this.next(placeId);
            });
        });
    }

    saveData2(data) {
        this.copyFields(data, () => {
            this.props.createOrUpdatePlace(this.state.place, (placeId) => {
                console.log("process form page 2 with SUCCESS");
                this.next(placeId);
            });
        });
    }

    saveData3(data) {
        const place = this.state.place;
        place.pictures = data;
        if (data.length > 0) {
            place.thumbnail = data[0].uri;
        }

        this.copyFields(place, () => {
            this.props.createOrUpdatePlace(this.state.place, (placeId) => {
                console.log("process form page 3 with SUCCESS");
                this.next(placeId);
            });
        });
    }

    saveData4(data) {
        const place = this.state.place;
        if (data.boundaries) {
            place.boundaries = data.boundaries;
        }

        if (data.gps) {
            place.gps = data.gps;
        }
        if (data.zoom) {
            place.zoom = data.zoom;
        }
        console.log("SAVE GPS AND ZOOM : ", data.gps, data.zoom);
        this.copyFields(place, () => {
            this.props.createOrUpdatePlace(this.state.place, (placeId) => {
                console.log("process form page 4 with SUCCESS");
                this.next(placeId);
            });
        });
    }

    saveData5(data) {
        this.copyFields(data, () => {
            this.props.createOrUpdatePlace(this.state.place, (placeId) => {
                console.log("process form page 5 with SUCCESS");
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.getPlaceList(this.props.customer.customerId);

                    if (this.editMode) {
                        this.props.loadPlace(this.props.currentPlace.placeId, this.props.history);
                        this.props.history.push("/places/view");
                    } else {
                        this.props.history.push("/places");
                    }
                }, 200);
            });
        });
    }

    nextPage(data) {
        this.setState({ loading: true });
        switch (this.state.page) {
            case 0:
                this.saveData1(data);
                break;
            case 1:
                this.saveData2(data);
                break;
            case 2:
                this.saveData3(data);
                break;
            case 3:
                this.saveData4(data);
                break;
            default:
                break;
        }
    }

    next = (placeId) => {
        console.log("NEXT");

        // apres la creation ajouter le placeid
        if (placeId && !this.state.place.placeId) {
            const place = this.state.place;
            place.placeId = placeId;
            this.setState({ place });
        }
        // passer à la page suivante
        setTimeout(() => {
            this.setState({ loading: false });
            this.setState({ page: this.state.page + 1 });
        }, 200);
    };

    previousPage() {
        this.setState({ page: this.state.page - 1 });
    }

    handleSubmit = (data) => {
        this.setState({ loading: true });
        this.saveData5(data);
    };

    setLoadingStatus(display) {
        this.setState({ loading: display });
    }
    exitEdition = () => {
        this.props.getPlaceList(this.props.customer.customerId);

        if (this.editMode) {
            this.props.loadPlace(this.props.currentPlace.placeId, this.props.history);
            this.props.history.push("/places/view");
        }
    };
    render() {
        //const { onSubmit } = this.props;
        const { page, steps } = this.state;
        if (!this.props.customer) {
            return <div className="text-danger">ERREUR DE CHARGEMENT</div>;
        }

        return (
            <div className="wizard">
                <Stepper className="" steps={steps} activeStep={page} lineHeight={32} />

                <Errors />
                <div className="card mt-4 p-4">
                    {page === 0 && (
                        <WizardFormPage1
                            editMode={this.editMode}
                            exitCallback={this.exitEdition}
                            loading={this.getLoadingClassName()}
                            onSubmit={this.nextPage}
                            initialValues={this.state.place}
                        />
                    )}
                    {page === 1 && (
                        <WizardFormPage2
                            editMode={this.editMode}
                            exitCallback={this.exitEdition}
                            previousPage={this.previousPage}
                            onSubmit={this.nextPage}
                            loading={this.getLoadingClassName()}
                            initialValues={this.state.place}
                        />
                    )}
                    {page === 2 && (
                        <WizardFormPage3
                            editMode={this.editMode}
                            exitCallback={this.exitEdition}
                            previousPage={this.previousPage}
                            onSubmit={this.nextPage}
                            loading={this.getLoadingClassName()}
                            initialValues={this.state.place}
                        />
                    )}
                    {page === 3 && (
                        <WizardFormPage4
                            editMode={this.editMode}
                            exitCallback={this.exitEdition}
                            previousPage={this.previousPage}
                            onSubmit={this.nextPage}
                            loading={this.getLoadingClassName()}
                            initialValues={this.state.place}
                        />
                    )}
                    {page === 4 && (
                        <WizardFormPage5
                            editMode={this.editMode}
                            exitCallback={this.exitEdition}
                            previousPage={this.previousPage}
                            onSubmit={this.handleSubmit}
                            loading={this.getLoadingClassName()}
                            initialValues={this.state.place}
                        />
                    )}
                </div>
            </div>
        );
    }
}

PlaceWizardForm.propTypes = {
    // onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        customer: state.customer.current,
        currentPlace: state.place.current,
    };
};
export default connect(mapStateToProps, { loadPlace, getPlaceList, createOrUpdatePlace })(
    PlaceWizardForm
);
