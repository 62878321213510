import React, { Component } from "react";
import PictureUploader from "../containers/PictureUploader";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import CONSTANTS from "../constants";
import { connect } from "react-redux";
import { displayError } from "../actions";
import * as Tools from "../misc/tools";
function truncate(size, str) {
    if (str.length > size) {
        return str.slice(0, size) + "...";
    } else {
        return str;
    }
}

const PictureItem = (props) => {
    const { index, legend, copyright, uri, deleteHandler, editHandler } = props;

    function deletePicture(e) {
        e.preventDefault();
        if (deleteHandler) deleteHandler(index);
    }

    function editPicture(e) {
        e.preventDefault();
        if (editHandler) editHandler(index);
    }

    console.log("--------RENDER PICTURE Item: ", uri);
    return (
        <div className="card picture-info m-3">
            <img src={uri} className="card-img-top picture-info-pic" alt="..." />

            <div className="card-body">
                <p>Légende : {legend}</p>
                <p>Crédit photo : {copyright}</p>
                <p>URL : {truncate(20, uri)}</p>
                <a onClick={deletePicture} href="/" className="card-link">
                    Supprimer
                </a>
                <a onClick={editPicture} href="/" className="card-link">
                    Modifier
                </a>
            </div>
        </div>
    );
};

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            CONSTANTS.IMAGE_MAX_WIDTH,
            CONSTANTS.IMAGE_MAX_WIDTH,
            "JPEG",
            CONSTANTS.IMAGE_QUALITY_POURCENTAGE,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

class PictureSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pictureList: [],
            legend: "",
            copyright: "",
            uri: "",
            file: {},
            editionIndex: -1, // si >= 0 alors it is the editing mode
            imagePreviewUrl: "",
            syncToogle: false,
            loading: false,
            placeId: null,
        };
    }

    componentDidMount() {
        window.$("#addPictureModal").on("hidden.bs.modal", (e) => {
            this.cancelModal();
        });
        if (this.props.pictures) {
            this.setState({ pictureList: this.props.pictures });
        }
        if (this.props.placeId) {
            this.setState({ placeId: this.props.placeId });
        }
    }

    pictureLoadedCallback = (data) => {
        console.log("LOADED : ", data);
        if (data.imagePreviewUrl === "") {
            return;
        }
        this.setState({
            file: data.file,
            imagePreviewUrl: data.imagePreviewUrl,
            uri: data.imagePreviewUrl,
        });
    };

    validateModal = () => {
        console.log("validate");
        this.setState({ loading: true });
        // do no enable empty picture
        if (this.state.uri !== "") {
            this.uploadPictureToServer(
                (fileUri) => {
                    // creation
                    if (this.state.editionIndex < 0) {
                        this.setState({
                            pictureList: [
                                ...this.state.pictureList,
                                {
                                    legend: this.state.legend,
                                    copyright: this.state.copyright,
                                    uri: fileUri,
                                },
                            ],
                        });
                        // edition
                    } else {
                        const pictureList = this.state.pictureList.map((item, i) => {
                            if (this.state.editionIndex === i) {
                                console.log("MISE A JOUR  REUSSIE : ", fileUri);
                                return {
                                    legend: this.state.legend,
                                    copyright: this.state.copyright,
                                    uri: fileUri,
                                };
                            } else {
                                return item;
                            }
                        });
                        this.setState({ pictureList: pictureList });
                    }
                    this.closeModalAndPassData();
                },
                () => {
                    this.closeModalAndPassData();
                }
            );
        } else {
            this.closeModalAndPassData();
        }
    };

    dataURLtoBlob = (dataurl) => {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    getExtension = (path) => {
        const tab = path.split(".");
        if (tab?.length > 1) {
            return tab[tab.length - 1];
        } else {
            return "";
        }
    };

    // remove later !!!
    generateUniqueFileName = (name, data) => {
        const newName =
            CONSTANTS.PICTURE_DIR_FILE_PATH +
            new Date().getTime() +
            "-" +
            name.replace(/[^a-z0-9.\s]/gi, "").replace(/[_\s]/g, "-");
        return newName;
    };

    uploadPictureToServer = async (successCallback, errorCallback) => {
        if (!this.state.uri.startsWith("data:")) {
            console.log("No need to upload data");
            successCallback(this.state.uri);
            return;
        }

        try {
            const resizedImageBlob = await resizeFile(this.state.file);

            // RETRIEVE THE S3 SIGNED URL to upload picture
            const serverURL =
                CONSTANTS.ASK_UPLOAD_URL +
                this.state.placeId +
                "?extension=" +
                this.getExtension(this.state.file.name) +
                "&index=" +
                this.state.editionIndex;

            console.log("TRY TO GET SIGNED URL, REQUEST URL : ", serverURL);

            let response = await axios({
                method: "get",
                url: serverURL,
                ...Tools.getAxiosOptionsAuth(),
                timeout: CONSTANTS.NETWORK_TIMOUT_MS,
            });
            if (!response) {
                throw new Error("ERROR during upload url retrieval");
            }
            const uploadUrl = response.data.uploadUrl;
            const storageFileName = response.data.storageFileName;
            console.log("storage file name :", storageFileName);
            console.log("signed url :", uploadUrl);

            // UPLOAD CONTENT
            //const blob = this.dataURLtoBlob(this.state.uri);
            response = await axios({
                method: "put",
                url: uploadUrl,
                data: resizedImageBlob,
                headers: { "Content-Type": "image/jpeg" }, //this.state.file.type
            });

            if (!response) {
                throw new Error("ERROR during upload of file");
            }

            console.log("Upload effectué avec succès");
            const data = {
                file: storageFileName,
                operation: this.state.editionIndex < 0 ? "create" : "update",
                index: this.state.editionIndex,
            };

            console.log("try to update server info : ", data);
            // Update server's URL once upload is successful
            response = await axios({
                method: "post",
                url: CONSTANTS.UPDATE_PICTURE_URL + this.state.placeId,
                data: data,
                ...Tools.getAxiosOptionsAuth(),
                timeout: CONSTANTS.NETWORK_TIMOUT_MS,
            });

            if (!response) {
                throw new Error("ERROR during updating of url in server side");
            }

            const pictureUrl =
                response.data.pictureUrl + "?v=" + Math.floor(new Date().getTime() / 1000);
            console.log("get as response of server the picture url : ", pictureUrl);
            // CALL SUCCESS CALLBACK
            if (successCallback) successCallback(pictureUrl);
        } catch (error) {
            console.log("Erreur serveur : ", error);
            // CALL ERROR CALLBACK
            if (errorCallback) errorCallback();
            this.props.displayError("Erreur serveur : Impossible d'importer les images");
        }
    };

    closeModalAndPassData = (nopass) => {
        this.setState({ syncToogle: !this.state.syncToogle }, () => {
            // only hide modal
            console.log("TRY TO HIDE MODAL");
            window.$("#addPictureModal").modal("hide");
            this.setState({ loading: false });
            if (!nopass) {
                // change occures
                if (this.props.onChange) {
                    this.props.onChange(this.state.pictureList);
                }
            }
        });
    };

    cancelModal = () => {
        console.log("clean");
        this.setState({
            legend: "",
            copyright: "",
            uri: "",
            file: {},
            imagePreviewUrl: "",
            editionIndex: -1,
        });
    };

    onChangeCopyright = (e) => {
        this.setState({ copyright: e.target.value });
    };
    onChangeLegend = (e) => {
        this.setState({ legend: e.target.value });
    };

    renderPicturesCards = (pictureList) => {
        return pictureList.map((pic, index) => {
            return (
                <PictureItem
                    key={index}
                    index={index}
                    legend={pic.legend}
                    copyright={pic.copyright}
                    uri={pic.uri}
                    deleteHandler={this.deletePicture}
                    editHandler={this.editPicture}
                />
            );
        });
    };

    deletePicture = (index) => {
        console.log("delete ", index);
        this.setState((state) => {
            const pictureList = state.pictureList.filter((item, i) => index !== i);
            this.removePictureFromServer(index);
            return { pictureList };
        });

        this.setState({ syncToogle: !this.state.syncToogle }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.pictureList);
            }
        });
    };

    removePictureFromServer = async (index) => {
        try {
            const item = this.state.pictureList[index];
            const parts = item.uri.split("/");
            const fileName = parts[parts.length - 1];

            // Delete and Update server's URL
            const response = await axios({
                method: "post",
                url: CONSTANTS.UPDATE_PICTURE_URL + this.state.placeId,
                data: {
                    file: fileName,
                    operation: "delete",
                    index: index,
                },
                ...Tools.getAxiosOptionsAuth(),
                timeout: CONSTANTS.NETWORK_TIMOUT_MS,
            });

            if (!response) {
                throw new Error("ERROR during deleting picture in server side");
            }
            console.log("Picture successfuly deleted in server");
        } catch (error) {
            console.log("Error : ", error);
        }
    };

    editPicture = (index) => {
        console.log("edit ", index);
        const item = this.state.pictureList[index];
        this.setState({
            editionIndex: index,
            uri: item.uri,
            legend: item.legend,
            copyright: item.copyright,
            imagePreviewUrl: item.uri,
        });

        window.$("#addPictureModal").modal("show");
    };

    getLoadingClassName = () => {
        if (this.state.loading) {
            return "spinner-border spinner-border-sm mr-2";
        } else {
            return "d-none";
        }
    };

    render() {
        console.log("--------RENDER PICTURE SELECTOR");
        return (
            <div>
                {this.state.pictureList.length < CONSTANTS.NUMBER_OF_PICTURES && (
                    <div>
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            data-toggle="modal"
                            data-target="#addPictureModal">
                            Ajouter une image
                        </button>
                    </div>
                )}
                <div
                    className="modal fade"
                    id="addPictureModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {this.state.editionIndex < 0
                                        ? "Ajouter une image"
                                        : "Modifier une image"}
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <PictureUploader
                                    imagePreviewUrl={this.state.imagePreviewUrl}
                                    loadedCallback={this.pictureLoadedCallback}
                                />
                                <fieldset className="form-group mt-3 mb-3">
                                    <label className="bmd-label-floating mb-1">Légende</label>
                                    <div>
                                        <input
                                            onChange={this.onChangeLegend}
                                            value={this.state.legend}
                                            className="form-control"
                                            type="text"
                                        />
                                    </div>
                                </fieldset>
                                <fieldset className="form-group mt-3 mb-3">
                                    <label className="bmd-label-floating mb-1">Crédit photo</label>
                                    <div>
                                        <input
                                            onChange={this.onChangeCopyright}
                                            value={this.state.copyright}
                                            className="form-control"
                                            type="text"
                                        />
                                    </div>
                                </fieldset>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal">
                                    Annuler
                                </button>
                                <button
                                    onClick={this.validateModal}
                                    type="button"
                                    className="btn btn-primary">
                                    <span
                                        className={this.getLoadingClassName()}
                                        role="status"
                                        aria-hidden="true"></span>
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">{this.renderPicturesCards(this.state.pictureList)}</div>
            </div>
        );
    }
}

export default connect(null, { displayError })(PictureSelector);
