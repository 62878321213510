import React from "react";
import { connect } from "react-redux";
import { loadPlace } from "../actions/index";

import "../css/PlaceItem.css";

const PlaceItem = (props) => {
    const { title, desc, pictureUri, placeId, history, deletionCallback } = props;

    function goToPlace(event, displayStats) {
        event.preventDefault();
        // history is retrieve thanks router V4
        props.loadPlace(placeId, history, displayStats);
    }
    function goToStats(event) {
        event.preventDefault();
        goToPlace(event, true);
    }

    function askDeletion(event) {
        event.preventDefault();
        if (deletionCallback) deletionCallback(placeId, title);
    }

    return (
        <div className="card card-place">
            <img src={pictureUri} className="card-img-top card-picture" alt="..." />
            <div className="">
                <div className="pl-3 pr-3 pt-3 flex-center">
                    <div className="card-place-title">{title}</div>
                </div>

                <div className="card-body card-place-link">
                    <div className="center px-2">
                        <div onClick={goToPlace} href="#" className="btn btn-primary w-75">
                            Gérer ce lieu
                        </div>
                    </div>
                    <div className="flex-center mt-3">
                        <div onClick={goToStats} href="#" className="btn btn-link ">
                            Voir les statistiques
                        </div>
                        <div onClick={askDeletion} href="#" className="btn btn-link">
                            Supprimer
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapDispatchToProps = {
    loadPlace,
};
export default connect(null, mapDispatchToProps)(PlaceItem);
