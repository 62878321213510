const COUNTRY_NAMES = {
    AFG: "Afghanistan",
    ALA: "Åland Islands",
    ALB: "Albanie",
    DZA: "Algérie",
    ASM: "Samoa",
    AND: "Andorre",
    AGO: "Angola",
    AIA: "Anguilla",
    ATA: "Antarctique",
    ATG: "Antigua et Barbuda",
    ARG: "Argentine",
    ARM: "Arménie",
    ABW: "Aruba",
    AUS: "Australie",
    AUT: "Autriche",
    AZE: "Azerbaïdjan",
    BHS: "Bahamas",
    BHR: "Bahrain",
    BGD: "Bangladesh",
    BRB: "Barbade",
    BLR: "Belarus",
    BEL: "Belgique",
    BLZ: "Belize",
    BEN: "Bénin",
    BMU: "Bermuda",
    BTN: "Bhutan",
    BOL: "Bolivie",
    BES: "Bonaire, Saint-Eustache et Saba",
    BIH: "Bosnie-Herzégovine",
    BWA: "Botswana",
    BVT: "Île Bouvet",
    BRA: "Brésil",
    IOT: "Territoire britannique de l'océan Indien",
    BRN: "Brunéi Darussalam",
    BGR: "Bulgarie",
    BFA: "Burkina Faso",
    BDI: "Burundi",
    KHM: "Cambodge",
    CMR: "Cameroun",
    CAN: "Canada",
    CPV: "Cap-Vert",
    CYM: "Îles Caïmans",
    CAF: "République centrafricaine",
    TCD: "Tchad",
    CHL: "Chili",
    CHN: "Chine",
    CXR: "Île Christmas",
    CCK: "Îles Cocos (Keeling)",
    COL: "Colombie",
    COM: "Comores",
    COG: "Congo",
    COD: "Congo, République démocratique du Congo",
    COK: "Îles Cook",
    CRI: "Costa Rica",
    CIV: "Côte d'Ivoire",
    HRV: "Croatie",
    CUB: "Cuba",
    CUW: "Curaçao",
    CYP: "Chypre",
    CZE: "République tchèque",
    DNK: "Danemark",
    DJI: "Djibouti",
    DMA: "Dominique",
    DOM: "République dominicaine",
    ECU: "Équateur",
    EGY: "Égypte",
    SLV: "El Salvador",
    GNQ: "Guinée équatoriale",
    ERI: "Érythrée",
    EST: "Estonie",
    ETH: "Éthiopie",
    FLK: "Îles Falkland (Malvinas)",
    FRO: "Îles Féroé",
    FJI: "Fidji",
    FIN: "Finlande",
    FRA: "France",
    GUF: "Guyane française",
    PYF: "Polynésie française",
    ATF: "Terres australes françaises",
    GAB: "Gabon",
    GMB: "Gambie",
    GEO: "Géorgie",
    DEU: "Allemagne",
    GHA: "Ghana",
    GIB: "Gibraltar",
    GRC: "Grèce",
    GRL: "Groenland",
    GRD: "Grenade",
    GLP: "Guadeloupe",
    GUM: "Guam",
    GTM: "Guatemala",
    GGY: "Guernesey",
    GIN: "Guinée",
    GNB: "Guinée-Bissau",
    GUY: "Guyane",
    HTI: "Haïti",
    HMD: "Île Heard et îles McDonald",
    VAT: "Saint-Siège (État de la Cité du Vatican)",
    HND: "Honduras",
    HKG: "Hong Kong",
    HUN: "Hongrie",
    ISL: "Islande",
    IND: "Inde",
    IDN: "Indonésie",
    IRN: "Iran, République islamique d Iran'",
    IRQ: "Irak",
    IRL: "Irlande",
    IMN: "Île de Man",
    ISR: "Israël",
    ITA: "Italie",
    JAM: "Jamaïque",
    JPN: "Japon",
    JEY: "Jersey",
    JOR: "Jordanie",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KIR: "Kiribati",
    PRK: "Corée, République populaire démocratique de Corée",
    KOR: "Corée, République de Corée",
    KWT: "Koweït",
    KGZ: "Kirghizistan",
    LAO: "République démocratique populaire lao",
    LVA: "Lettonie",
    LBN: "Liban",
    LSO: "Lesotho",
    LBR: "Liberia",
    LBY: "Libye",
    LIE: "Liechtenstein",
    LTU: "Lituanie",
    LUX: "Luxembourg",
    MAC: "Macao",
    MKD: "Macédoine, ancienne République de Yougoslavie",
    MDG: "Madagascar",
    MWI: "Malawi",
    MYS: "Malaisie",
    MDV: "Maldives",
    MLI: "Mali",
    MLT: "Malte",
    MHL: "Îles Marshall",
    MTQ: "Martinique",
    MRT: "Mauritanie",
    MUS: "Maurice",
    MYT: "Mayotte",
    MEX: "Mexique",
    FSM: "Micronésie, États fédérés de Micronésie",
    MDA: "Moldavie, République de Moldavie",
    MCO: "Monaco",
    MNG: "Mongolie",
    MNE: "Monténégro",
    MSR: "Montserrat",
    MAR: "Maroc",
    MOZ: "Mozambique",
    MMR: "Myanmar",
    NAM: "Namibie",
    NRU: "Nauru",
    NPL: "Népal",
    NLD: "Pays-Bas",
    NCL: "Nouvelle-Calédonie",
    NZL: "Nouvelle-Zélande",
    NIC: "Nicaragua",
    NER: "Niger",
    NGA: "Nigéria",
    NIU: "Niue",
    NFK: "Île Norfolk",
    MNP: "Îles Mariannes du Nord",
    NOR: "Norvège",
    OMN: "Oman",
    PAK: "Pakistan",
    PLW: "Palau",
    PSE: "Palestine",
    PAN: "Panama",
    PNG: "Papouasie-Nouvelle-Guinée",
    PRY: "Paraguay",
    PER: "Pérou",
    PHL: "Philippines",
    PCN: "Pitcairn",
    POL: "Pologne",
    PRT: "Portugal",
    PRI: "Porto Rico",
    QAT: "Qatar",
    REU: "Réunion",
    ROU: "Roumanie",
    RUS: "Fédération de Russie",
    RWA: "Rwanda",
    BLM: "Saint Barthélemy",
    SHN: "Sainte-Hélène, Ascension et Tristan da Cunha",
    KNA: "Saint-Kitts-et-Nevis",
    LCA: "Sainte-Lucie",
    MAF: "Saint-Martin (partie française)",
    SPM: "Saint-Pierre-et-Miquelon",
    VCT: "Saint-Vincent-et-les Grenadines",
    WSM: "Samoa",
    SMR: "Saint-Marin",
    STP: "Sao Tomé-et-Principe",
    SAU: "Arabie saoudite",
    SEN: "Sénégal",
    SRB: "Serbie",
    SYC: "Seychelles",
    SLE: "Sierra Leone",
    SGP: "Singapour",
    SXM: "Sint Maarten (partie néerlandaise)",
    SVK: "Slovaquie",
    SVN: "Slovénie",
    SLB: "Îles Salomon",
    SOM: "Somalie",
    ZAF: "Afrique du Sud",
    SGS: "Géorgie du Sud et îles Sandwich du Sud",
    SSD: "Soudan du Sud",
    ESP: "Espagne",
    LKA: "Sri Lanka",
    SDN: "Soudan",
    SUR: "Suriname",
    SJM: "Svalbard et Jan Mayen",
    SWZ: "Swaziland",
    SWE: "Suède",
    CHE: "Suisse",
    SYR: "République arabe syrienne",
    TWN: "Taïwan, province de Chine",
    TJK: "Tadjikistan",
    TZA: "Tanzanie, République-Unie de Tanzanie",
    THA: "Thaïlande",
    TLS: "Timor-Leste",
    TGO: "Togo",
    TKL: "Tokelau",
    TON: "Tonga",
    TTO: "Trinité-et-Tobago",
    TUN: "Tunisie",
    TUR: "Turquie",
    TKM: "Turkménistan",
    TCA: "Îles Turques et Caïques",
    TUV: "Tuvalu",
    UGA: "Ouganda",
    UKR: "Ukraine",
    ARE: "Émirats arabes unis",
    GBR: "Royaume-Uni",
    USA: "États-Unis",
    UMI: "Îles mineures éloignées des États-Unis",
    URY: "Uruguay",
    UZB: "Ouzbékistan",
    VUT: "Vanuatu",
    VEN: "Venezuela, République bolivarienne",
    VNM: "Viet Nam",
    VGB: "Îles Vierges britanniques",
    VIR: "Îles Vierges américaines.",
    WLF: "Wallis et Futuna",
    ESH: "Sahara occidental",
    YEM: "Yémen",
    ZMB: "Zambie",
    ZWE: "Zimbabwe",
};
export default COUNTRY_NAMES;
