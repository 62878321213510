import { SET_OFFERS } from "../actions/action-types";

export default function OfferReducer(state = [], action) {
    switch (action.type) {
        case SET_OFFERS:
            return action.payload;

        default:
            return state;
    }
}
