import { SET_TODAY_STATS, SET_YEAR_STATS } from "../actions/action-types";
const defaultValue = {
    yearData: { invitations: [], opened: [], views: [], records: [] },
    monthData: { invitations: [], opened: [], views: [], records: [] },
    weekData: { invitations: [], opened: [], views: [], records: [] },
    yesterdayData: { invitations: [], opened: [], views: [], records: [] },
    todayData: { invitations: [], opened: [], views: [], records: [] },
    yearStats: { invitations: 0, opened: 0, views: 0, records: 0 },
    monthStats: { invitations: 0, opened: 0, views: 0, records: 0 },
    weekStats: { invitations: 0, opened: 0, views: 0, records: 0 },
    yesterdayStats: { invitations: 0, opened: 0, views: 0, records: 0 },
    todayStats: { invitations: 0, opened: 0, views: 0, records: 0 },
};

export default function StatsReducer(state = defaultValue, action) {
    switch (action.type) {
        case SET_YEAR_STATS:
            const yearData = getYearData(action.payload);
            const yesterdayData = getYesterdayData(action.payload);
            const todayData = getTodayData(action.payload);
            const monthData = getMonthDataFromYear(yearData);
            const weekData = getWeekDataFromYear(yearData);

            const yearStats = getYearStats(action.payload);
            const monthStats = getMonthStats(action.payload);
            const weekStats = getWeekStats(action.payload);
            const yesterdayStats = getYesterdayStats(action.payload);
            const todayStats = getTodayStats(action.payload);

            return {
                yearData: yearData,
                monthData: monthData,
                weekData: weekData,
                yesterdayData: yesterdayData,
                todayData: todayData,

                yearStats: yearStats,
                monthStats: monthStats,
                weekStats: weekStats,
                yesterdayStats: yesterdayStats,
                todayStats: todayStats,
            };
        case SET_TODAY_STATS:
            return {
                ...state,
                todayStats: getTodayStats(action.payload),
                todayData: getTodayData(action.payload),
            };
        default:
            return state;
    }
}

function getYearData(data) {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 366);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - 1);
    const startDayNumber = getEpocDayNumberFromDate(startDate);
    const endDayNumber = getEpocDayNumberFromDate(endDate);

    let tabRecord = [];
    let tabInvitation = [];
    let tabOpen = [];
    let tabRender = [];
    // init all date with 0 values
    for (let i = startDayNumber; i < endDayNumber + 1; i++) {
        tabRecord.push([getDateStr(endDayNumber - i + 1), 0]);
        tabInvitation.push([getDateStr(endDayNumber - i + 1), 0]);
        tabOpen.push([getDateStr(endDayNumber - i + 1), 0]);
        tabRender.push([getDateStr(endDayNumber - i + 1), 0]);
    }

    // now set values when is required
    for (let i = 0; i < data.length; i++) {
        if (startDayNumber <= data[i].ds && data[i].ds <= endDayNumber) {
            tabRecord[data[i].ds - startDayNumber][1] = data[i].nbRecord;
            tabInvitation[data[i].ds - startDayNumber][1] = data[i].nbInvite;
            tabOpen[data[i].ds - startDayNumber][1] = data[i].nbOpen;
            tabRender[data[i].ds - startDayNumber][1] = data[i].nbRender;
        }
    }
    return { records: tabRecord, invitations: tabInvitation, opened: tabOpen, views: tabRender };
}

function getWeekDataFromYear(yearData) {
    return {
        records: yearData.records.slice(yearData.records.length - 7),
        invitations: yearData.invitations.slice(yearData.invitations.length - 7),
        opened: yearData.opened.slice(yearData.opened.length - 7),
        views: yearData.views.slice(yearData.views.length - 7),
    };
}

function getMonthDataFromYear(yearData) {
    return {
        records: yearData.records.slice(yearData.records.length - 30),
        invitations: yearData.invitations.slice(yearData.invitations.length - 30),
        opened: yearData.opened.slice(yearData.opened.length - 30),
        views: yearData.views.slice(yearData.views.length - 30),
    };
}

function getYesterdayData(data) {
    return getOneDayData(data, -1);
}

function getTodayData(data) {
    return getOneDayData(data, 0);
}

function getOneDayData(data, index) {
    const today = new Date();
    let todayNumber = getEpocDayNumberFromDate(today) + index;

    let tabRecord = [];
    let tabInvitation = [];
    let tabOpen = [];
    let tabRender = [];
    // init all date with 0 values
    for (let i = 8; i < 23 + 1; i++) {
        tabRecord.push([i + "h", 0]);
        tabInvitation.push([i + "h", 0]);
        tabOpen.push([i + "h", 0]);
        tabRender.push([i + "h", 0]);
    }

    // now set values when is required
    for (let i = 0; i < data.length; i++) {
        if (todayNumber === data[i].ds && data[i].activity) {
            for (let j = 8; j < 23; j++) {
                if (data[i].activity.R && data[i].activity.R["" + j]) {
                    tabRecord[j - 8][1] = data[i].activity.R["" + j];
                }
                if (data[i].activity.I && data[i].activity.I["" + j]) {
                    tabInvitation[j - 8][1] = data[i].activity.I["" + j];
                }
                if (data[i].activity.O && data[i].activity.O["" + j]) {
                    tabOpen[j - 8][1] = data[i].activity.O["" + j];
                }
                if (data[i].activity.V && data[i].activity.V["" + j]) {
                    tabRender[j - 8][1] = data[i].activity.V["" + j];
                }
            }
        }
    }

    return {
        records: tabRecord,
        invitations: tabInvitation,
        opened: tabOpen,
        views: tabRender,
    };
}

function getYearStats(data) {
    return getStatsFromDate(data, -366, -1);
}

function getMonthStats(data) {
    return getStatsFromDate(data, -31, -1);
}

function getWeekStats(data) {
    return getStatsFromDate(data, -8, -1);
}

function getYesterdayStats(data) {
    return getStatsFromDate(data, -1, -1);
}

function getTodayStats(data) {
    return getStatsFromDate(data, 0, 0);
}

function getStatsFromDate(data, indexStart, indexEnd) {
    console.log("DEBUG getStatsFromDate : ", data, indexStart, indexEnd);
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + indexStart);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + indexEnd);
    const startDayNumber = getEpocDayNumberFromDate(startDate);
    const endDayNumber = getEpocDayNumberFromDate(endDate);

    let invitations = 0;
    let opened = 0;
    let views = 0;
    let records = 0;
    for (let i = 0; i < data.length; i++) {
        if (startDayNumber <= data[i].ds && data[i].ds <= endDayNumber) {
            records += data[i].nbRecord;
            invitations += data[i].nbInvite;
            opened += data[i].nbOpen;
            views += data[i].nbRender;
        }
    }
    return { invitations, opened, views, records };
}

function getEpocDayNumberFromMS(value) {
    return Math.floor(value / (1000 * 60 * 60 * 24));
}

function getEpocDayNumberFromDate(date) {
    return getEpocDayNumberFromMS(date.getTime());
}

function getDateStr(index) {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - index);
    return newDate.toLocaleDateString("fr-FR", { month: "long", day: "numeric" });
}
