import React, { Component } from "react";
import { connect } from "react-redux";
import Errors from "./Errors";
import { displayError, askPasswordReset } from "../actions";
import CONSTANTS from "../constants";
import axios from "axios";
import * as Tools from "../misc/tools";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            errorMessage: null,
            successMessage: null,
            processing: false,
        };
    }

    handleEmail = (e) => {
        this.setState({ email: e.target.value, errorMessage: null });
    };

    goSignIn = () => {
        this.props.history.push("/signin");
    };

    askPasswordReset = () => {
        const email = this.state.email;
        if (this.state.processing || email?.length === 0) return;

        if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            console.log("invalid email");
            this.setState({ errorMessage: "Email invalide" });
            return;
        }

        this.setState({ processing: true }, () => {
            this.props.askPasswordReset(email, this.successResetCallback, this.errorResetCallback);
        });
    };

    successResetCallback = async () => {
        this.setState({
            processing: false,
            errorMessage: null,
            successMessage: "Email envoyé avec succès.",
        });

        setTimeout(() => {
            this.props.history.push("/signin");
        }, 2000);
    };

    errorResetCallback = (error) => {
        this.setState({ processing: false });
    };

    getLoadingClassName = () => {
        if (this.state.processing) {
            return "spinner-border spinner-border-sm mr-2";
        } else {
            return "d-none";
        }
    };

    render() {
        const { errorMessage, processing, successMessage, email } = this.state;
        return (
            <div className="flex-center">
                <div className="center mt-5 mb-3">
                    <img src="/banner-starplace.png" width="400" alt="" className="center" />
                </div>
                <div className="">
                    <div style={{ maxWidth: 500 }}>
                        <Errors />
                    </div>
                </div>
                <div style={{ maxWidth: 500 }} className="ml-5 mr-5">
                    <div className="mt-3 h5">Mot de passe oublié</div>
                    <div className="mt-4 mb-5" style={{ color: "#777" }}>
                        Entrez votre adresse email, nous vous enverrons un lien par email pour
                        réinitialiser votre mot de passe.
                    </div>
                    <div className="mt-3 mb-3">
                        <fieldset className="form-group">
                            <div
                                style={{
                                    backgroundColor: "yellow",
                                    maxWidth: 300,
                                    margin: "auto",
                                }}>
                                <input
                                    value={this.state.email}
                                    onChange={this.handleEmail}
                                    type="text"
                                    className="form-control"
                                    placeholder="Entrez votre email"
                                />
                            </div>
                        </fieldset>
                    </div>

                    {errorMessage && (
                        <div style={{ color: "red" }} className="mt-3 mb-3">
                            {errorMessage}
                        </div>
                    )}
                    {successMessage && (
                        <div style={{ color: "blue" }} className="mt-3 mb-3">
                            {successMessage}
                        </div>
                    )}
                    <button
                        style={{ width: 300 }}
                        className="btn btn-primary "
                        disabled={processing}
                        onClick={this.askPasswordReset}>
                        <span
                            className={this.getLoadingClassName()}
                            role="status"
                            aria-hidden="true"></span>
                        Réinitaliser le mot de passe
                    </button>

                    <div className="center mt-5 mb-3">
                        <div style={{ color: "#777" }}>
                            <a
                                style={{ color: "#777", textDecoration: "underline" }}
                                href="/signin">
                                Retour à la page de connexion
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, { displayError, askPasswordReset })(ForgotPassword);
