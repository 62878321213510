import {
    SET_PLACE_LIST,
    RESET_ALL_PLACES,
    SET_CURRENT_PLACE,
    RESET_CURRENT_PLACE,
} from "../actions/action-types";

export default function PlaceReducer(state = { list: [], current: null }, action) {
    switch (action.type) {
        case SET_PLACE_LIST:
            return {
                ...state,
                list: action.payload,
            };
        case SET_CURRENT_PLACE:
            return {
                ...state,
                current: action.payload,
            };
        case RESET_CURRENT_PLACE:
            return {
                ...state,
                current: null,
            };
        case RESET_ALL_PLACES:
            return {
                list: [],
                current: null,
            };

        default:
            return state;
    }
}
